import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "../../info.scss";

const Promise = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.promise?.title,
                }}
              />
            </Typography>
            <Typography variant="h5">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.promise?.head1,
                }}
              />
            </Typography>
            <Typography variant="h5" className="plateform_top_inner ml-18">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.promise?.desc1,
                }}
              />
            </Typography>
            <Typography
              variant="h5"
              className="color-medium-gray mb-17"
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.promise?.desc2,
              }}
            />
            <Typography
              variant="h5"
              className="color-medium-gray mb-17"
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.promise?.desc3,
              }}
            />
            <Typography
              variant="h5"
              className="color-medium-gray mb-17"
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.promise?.desc4,
              }}
            />
            <Typography
              variant="h5"
              className="color-medium-gray mb-17"
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.promise?.desc5,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Promise;
