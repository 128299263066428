import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  // Select,
  // MenuItem,
  FormHelperText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { IntlContext } from 'src/App';
import { CustomTextField } from 'src/views/component/UI/textfield';
// import { CustomSelectInput } from 'src/views/component/UI/select';

import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/dropdown.svg';
import SpportModel from './SupportTicket/SpportModel';
import { Config } from 'src/helpers/context';
import './contactUs.scss';

const ContactSupport = () => {
  const userRoute = Config?.userRoutePath;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  let getCategory = new URLSearchParams(window.location.search).get('category');
  const [toggleOpen, setToggleOpen] = useState(getCategory ? true : false);
  const [togglesite, settogglesite] = useState(getCategory ? true : false);
  const [category, setCategory] = useState(getCategory);
  const [siteData, setSiteData] = useState({ feature: '', issueDetails: '' });
  const [errors, setErrors] = useState({});

  const featureOptions = [
    {
      id: 1,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt1,
    },
    {
      id: 2,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt2,
    },
    {
      id: 3,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt3,
    },
    {
      id: 4,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt4,
    },
    {
      id: 5,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt5,
    },
    {
      id: 6,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt6,
    },
    {
      id: 7,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt7,
    },
    {
      id: 8,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt8,
    },
    {
      id: 9,
      name: localesData?.info_content?.help_center?.contact?.features_opt?.opt9,
    },
    {
      id: 10,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt10,
    },
    {
      id: 11,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt11,
    },
    {
      id: 12,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt12,
    },
    {
      id: 13,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt13,
    },
    {
      id: 14,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt14,
    },
    {
      id: 15,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt15,
    },
    {
      id: 16,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt16,
    },
    {
      id: 17,
      name:
        localesData?.info_content?.help_center?.contact?.features_opt?.opt17,
    },
  ];
  const handleChangeSelect = (name, values) => {
    setSiteData({ ...siteData, [name]: values });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteData({ ...siteData, [name]: value });
    if (name === 'feature') {
      setErrors({ ...errors, feature: '' });
    }
    if (name === 'issueDetails') {
      setErrors({ ...errors, issueDetails: '' });
    }
  };
  const validation = () => {
    let error = {};
    let flag = true;
    let val = siteData;

    if (val?.feature === '') {
      error.feature = localesData?.validation?.required_message;
      flag = false;
    }
    if (val?.issueDetails === '') {
      error.issueDetails = localesData?.validation?.required_message;
      flag = false;
    }
    setErrors(error);
    return flag;
  };
  const handleOpenNext = () => {
    if (validation()) {
      settogglesite(true);
      setCategory('sst26');
    }
  };

  return (
    <Box className='main-content-block'>
      <Grid container className='main-grid-container main-section-block'>
        <Grid item xs={12} sm={12} md={12} lg={12} className=''>
          <Box className='plateform_top'>
            <Typography variant='h3' className='info_head info_head_wrap mb-17'>
              {localesData?.info_content?.help_center?.contact?.sezzyou_support}
            </Typography>
            <Box className='plateform_top_inner'>
              <Typography
                variant='h5'
                className='mb-17'
                dangerouslySetInnerHTML={{
                  __html:
                    localesData?.info_content?.help_center?.contact?.desc1,
                }}
              />
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact?.reg,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.reg_desc,
                    }}
                  />
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_head1,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_desc1_1,
                        }}
                      />

                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_desc1_2,
                        }}
                      />

                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst1');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_head2,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_desc2_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc2_2,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst2');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.reg_head4,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc3_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst3');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.ac_access,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.reg_desc,
                    }}
                  />
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_head1,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5' className='mb-10'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.ac_desc1_1,
                          }}
                        />
                        <Link
                          to={`${userRoute}reset-password`}
                          className='link_text'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.ac_desc1_2
                          }
                        </Link>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.ac_desc1_3,
                          }}
                        />
                      </Typography>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_desc1_4,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_head2,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.ac_desc2_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst4');
                          }}
                          className='text-btn link_text btn-click-here'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_head3,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_desc3_1,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_head4,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_desc4_1,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.ac_head5,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.ac_desc5_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst7');
                          }}
                          className='text-btn link_text btn-click-here'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact?.sezzme,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.reg_desc,
                    }}
                  />
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head1,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc1_2,
                        }}
                      />
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc1_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst8');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head2,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc2_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc2_2,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc2_3,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst9');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head3,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc3_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc3_2,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst10');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head4,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc4_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc4_2,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst11');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head5,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc5_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc5_2,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc5_3,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst12');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head6,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc6_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc6_2,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc6_3,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst13');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head7,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_desc7_1,
                        }}
                      />
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc7_2,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst15');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.sezzme_head9,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.sezzme_desc9_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst17');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel3a-content'
                  id='panel3a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.pl_function,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant='h5'
                    className='mb-10'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.pl_desc1_1,
                    }}
                  />
                  <Typography
                    variant='h5'
                    className='mb-17'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.pl_desc1_2,
                    }}
                  />
                  <Box className='mb-17 site-data-info'>
                    <FormHelperText className='custom-field-helper'>
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.pl_function
                      }
                    </FormHelperText>
                    <Autocomplete
                      options={featureOptions}
                      getOptionLabel={(option) => option?.name || ''}
                      value={
                        featureOptions?.find((item) => {
                          return item?.name === siteData?.feature;
                        }) || ''
                      }
                      onChange={(e, newValue) => {
                        handleChangeSelect(
                          'feature',
                          newValue?.name ? newValue?.name : ''
                        );
                      }}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          className='custom-textfield autocomplete-field'
                          placeholder={localesData?.select}
                        />
                      )}
                      popupIcon={<DropdownIcon />}
                    />
                    {/* <Select
                      error={errors.feature ? true : false}
                      fullWidth
                      value={siteData?.feature || ''}
                      onChange={(e) => handleChange(e)}
                      input={<CustomSelectInput />}
                      className='custom-select'
                      displayEmpty
                      name='feature'
                      IconComponent={DropdownIcon}
                    >
                      <MenuItem value=''>{localesData?.select}</MenuItem>
                      {featureOptions?.map((item, i) => (
                        <MenuItem value={item?.name} key={i}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    {errors.feature && (
                      <FormHelperText error={errors.feature ? true : false}>
                        {errors.feature}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box className='mb-17 '>
                    <FormHelperText className='custom-field-helper'>
                      {localesData?.info_content?.help_center?.issue_details}:
                      <span className='valid_star'>*</span>
                    </FormHelperText>
                    <CustomTextField
                      error={Boolean(errors.issueDetails)}
                      helperText={errors.issueDetails}
                      fullWidth
                      variant='outlined'
                      className='custom-textfield custom-multiline'
                      name='issueDetails'
                      onChange={(e) => handleChange(e)}
                      value={siteData?.issueDetails || ''}
                      multiline
                      minRows={4}
                    />
                  </Box>
                  <Button className='btn-blue mb-17' onClick={handleOpenNext}>
                    {localesData?.info_content?.help_center?.next}
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel3a-content'
                  id='panel3a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact?.report,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head1,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_desc1_1_1,
                        }}
                      />
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_desc1_1,
                        }}
                      />
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_desc1_2,
                        }}
                      />
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_desc1_3,
                        }}
                      />
                      <Typography
                        variant='h5'
                        className='mb-17'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_desc1_4,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head2,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5' className='mb-17'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc2_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst27');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head3,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc3_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst29');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head4,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc4_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst30');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head5,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc5_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst31');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head6,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc6_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst32');
                          }}
                          className=' btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head7,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc7_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst33');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      className='accordion_text'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel3a-content'
                      id='panel3a-header'
                    >
                      <Typography
                        variant='h5'
                        dangerouslySetInnerHTML={{
                          __html:
                            localesData?.info_content?.help_center?.contact
                              ?.report_head8,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='h5'>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.report_desc8_1,
                          }}
                        />
                        <Button
                          onClick={() => {
                            setToggleOpen(true);
                            setCategory('sst34');
                          }}
                          className='btn-click-here link_text text-btn'
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.click_here
                          }
                        </Button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              localesData?.info_content?.help_center?.contact
                                ?.reg_desc1_3_1,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className='accordion_text'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    variant='h5'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact?.other,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant='h5'
                    className='mb-17'
                    dangerouslySetInnerHTML={{
                      __html:
                        localesData?.info_content?.help_center?.contact
                          ?.other_desc1,
                    }}
                  />
                  <Typography variant='h5' className='mb-17'>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.contact
                            ?.other_desc2,
                      }}
                    />
                    <Button
                      onClick={() => {
                        setToggleOpen(true);
                        setCategory('sst36');
                      }}
                      className='text-btn link_text btn-click-here'
                    >
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.click_here
                      }
                    </Button>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.contact
                            ?.reg_desc1_3_1,
                      }}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Grid>
        <SpportModel
          open={togglesite}
          handleClose={() => settogglesite(!togglesite)}
          title={localesData?.info_content?.help_center?.contact?.submit_title}
          category={category}
          siteFeature={siteData}
          setSiteData={setSiteData}
          localesData={localesData}
        />
        <SpportModel
          open={toggleOpen}
          handleClose={() => setToggleOpen(!toggleOpen)}
          title={localesData?.info_content?.help_center?.contact?.contact_title}
          category={category}
          localesData={localesData}
        />
      </Grid>
    </Box>
  );
};

export default ContactSupport;
