import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Config, fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

import TopBar from './TopBar';
import 'src/layouts/layout.scss';
import 'src/views/component/auth/auth.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  layoutWrapper: {
    marginTop: 0,
  },
}));

const DashboardLayout = () => {
  const userRoute = Config?.userRoutePath;
  const classes = useStyles();
  const location = useLocation();
  const redux_auth = useSelector((state) => state?.reduxData?.authData);
  const auth = redux_auth ? redux_auth : fetchFromStorage(identifiers.authData);
  const verify_count = useSelector(
    (state) => state?.reduxData?.user_profile_count
  );
  return (
    <div className={classes.root}>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      {!location.pathname.includes(userRoute) && (
        <TopBar auth={auth} verify_count={verify_count} />
      )}
      <div
        className={
          location.pathname.includes(userRoute)
            ? classes.layoutWrapper + ' layout-wrapper'
            : 'layout-wrapper'
        }
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
