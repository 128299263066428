import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";

const TrendingInfo = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {localesData?.home_page?.trending}
            </Typography>

            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.home_page?.trending
                    ?.desc2,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.home_page?.trending
                    ?.desc3,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.home_page?.trending
                    ?.desc4,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TrendingInfo;
