import React, { useEffect, useState } from 'react';
import DialogBox from 'src/views/component/UI/dialogBox';
import {
  Box,
  Grid,
  Button,
  Checkbox,
  // MenuItem,
  Typography,
  // Select,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { Autocomplete } from '@material-ui/lab';
// import { CustomSelectInput } from 'src/views/component/UI/select';
import { identifiers } from 'src/helpers/constants/identifier';
import { Config, fetchFromStorage } from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/dropdown.svg';
import { ReactComponent as CheckIcon } from 'src/assets/images/icons/checked-checkbox.svg';
import { ReactComponent as UncheckIcon } from 'src/assets/images/icons/unchecked-checkbox.svg';

import moment from 'moment';
import PreLoader from 'src/components/Loader';
import { Link } from 'react-router-dom';
import '../../../info.scss';

const SpportModel = ({
  open,
  handleClose,
  title,
  category,
  siteFeature,
  setSiteData,
  localesData,
}) => {
  const userRoute = Config?.userRoutePath;
  let getUserID = new URLSearchParams(window.location.search).get('user_id');
  let getIssue = new URLSearchParams(window.location.search).get('issue_title');
  let getIssueDetails = new URLSearchParams(window.location.search).get(
    'issue_details'
  );

  const loginUser = fetchFromStorage(identifiers.userData);
  const userID = getUserID ? getUserID : loginUser?._id;

  const [loader, setLoader] = useState(false);
  const [ticketData, setTicketData] = useState('');
  const [ticket, setTicket] = useState({
    registeredType: '0',
    registeredName: '',
    registeredUserName: '',
    registeredEmail: '',
    issueDetails: '',
    groupName: '',
    groupUserName: '',
    blockedName: '',
    // blockedUserName: "",
    // name: "",
    // username: "",
    // email: "",
    // phoneNumber: "",
    // phoneCountry: "",
    relationship: '',
    impersonatedName: '',
    impersonatedUsername: '',
    reportedName: '',
    reportedUsername: '',
    furtherDetails: '',
    bizName: '',
    bizAddress1: '',
    // bizAddress2: "",
    // bizTown: "",
    // bizState: "",
    // bizCountry: "",
    // bizPost: "",
    bizWebsite: '',
    bizUserName: '',
    bizWay: '',
    markSymbol: '',
    markNumber: '',
    markClass: '',
    markOffice: '',
    markLink: '',
    copyRightInfo: '',
    copyRightURL: '',
    aggRegisteredType: '0',
    aggRegisteredName: '',
    aggRegisteredUserName: '',
    issueTitle: '',
  });
  const [acceptInfo, setAcceptInfo] = useState(false);
  const [violations, setViolations] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  // const userType = [
  //   { type: localesData?.listener?.individual, value: "0" },
  //   { type: localesData?.listener?.group, value: "1" },
  // ];
  const wayBusiness = [
    { type: localesData?.way_business?.business_name, value: 'Business Name' },
    {
      type: localesData?.way_business?.business_image,
      value: 'Business Image',
    },
    {
      type: localesData?.way_business?.business_details,
      value: 'Business Details',
    },
  ];
  let sst1 = loginUser || category === 'sst1';
  let sst2 = loginUser || category === 'sst2';
  let sst3 = loginUser || category === 'sst3';
  let sst4 = loginUser || category === 'sst4';
  let sst7 = loginUser || category === 'sst7';
  let sst8 = loginUser && category === 'sst8';
  let sst9 = loginUser && category === 'sst9';
  let sst10 = loginUser && category === 'sst10';
  let sst11 = loginUser && category === 'sst11';
  let sst12 = loginUser && category === 'sst12';
  let sst13 = loginUser && category === 'sst13';
  let sst15 = loginUser && category === 'sst15';
  let sst16 = loginUser && category === 'sst16';
  let sst17 = loginUser && category === 'sst17';
  let sst26 = loginUser || category === 'sst26';
  let sst27 = loginUser || category === 'sst27';
  let sst28 = loginUser || category === 'sst28';
  let sst29 = loginUser || category === 'sst29';
  let sst30 = loginUser || category === 'sst30';
  let sst31 = loginUser || category === 'sst31';
  let sst32 = loginUser || category === 'sst32';
  let sst33 = loginUser && category === 'sst33';
  let sst34 = loginUser && category === 'sst34';
  let sst36 = loginUser || category === 'sst36';

  const handleClickCheckBox = (value) => {
    const isChecked = violations.includes(value);
    let updatedList;

    if (isChecked) {
      updatedList = violations.filter((item) => item !== value);
    } else {
      updatedList = [...violations, value];
    }

    setViolations(updatedList);
  };

  const handleChangeSelect = (name, values) => {
    setTicket({ ...ticket, [name]: values });
    if (name === 'bizWay') {
      setErrors({ ...errors, bizWay: '' });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'acceptInfo') {
        setAcceptInfo(checked);
      } else {
        var updatedList = [...violations];
        if (checked) {
          updatedList = [...violations, value];
        } else {
          updatedList.splice(violations?.indexOf(value), 1);
        }
        setViolations(updatedList);
      }
    }

    setTicket({ ...ticket, [name]: value });
    if (name === 'registeredType') {
      setErrors({ ...errors, registeredType: '' });
    }
    if (name === 'registeredName') {
      setErrors({ ...errors, registeredName: '' });
    }
    if (name === 'registeredUserName') {
      setErrors({ ...errors, registeredUserName: '' });
    }
    if (name === 'registeredEmail') {
      setErrors({ ...errors, registeredEmail: '' });
    }
    if (name === 'issueDetails') {
      setErrors({ ...errors, issueDetails: '' });
    }
    if (name === 'groupName') {
      setErrors({ ...errors, groupName: '' });
    }
    if (name === 'groupUserName') {
      setErrors({ ...errors, groupUserName: '' });
    }
    if (name === 'blockedName') {
      setErrors({ ...errors, blockedName: '' });
    }
    // if (name === "blockedUserName") {
    //   setErrors({ ...errors, blockedUserName: "" });
    // }
    // if (name === "name") {
    //   setErrors({ ...errors, name: "" });
    // }
    // if (name === "email") {
    //   setErrors({ ...errors, email: "" });
    // }
    // if (name === "phoneNumber") {
    //   setErrors({ ...errors, phoneNumber: "" });
    // }
    // if (name === "phoneCountry") {
    //   setErrors({ ...errors, phoneCountry: "" });
    // }
    if (name === 'relationship') {
      setErrors({ ...errors, relationship: '' });
    }
    if (name === 'impersonatedName') {
      setErrors({ ...errors, impersonatedName: '' });
    }
    if (name === 'reportedName') {
      setErrors({ ...errors, reportedName: '' });
    }
    if (name === 'reportedUsername') {
      setErrors({ ...errors, reportedUsername: '' });
    }
    if (name === 'furtherDetails') {
      setErrors({ ...errors, furtherDetails: '' });
    }
    if (name === 'bizName') {
      setErrors({ ...errors, bizName: '' });
    }
    if (name === 'bizAddress1') {
      setErrors({ ...errors, bizAddress1: '' });
    }
    if (name === 'bizTown') {
      setErrors({ ...errors, bizTown: '' });
    }
    if (name === 'bizCountry') {
      setErrors({ ...errors, bizCountry: '' });
    }
    if (name === 'bizPost') {
      setErrors({ ...errors, bizPost: '' });
    }
    if (name === 'bizWebsite') {
      setErrors({ ...errors, bizWebsite: '' });
    }
    // if (name === 'bizWay') {
    //   setErrors({ ...errors, bizWay: '' });
    // }
    if (name === 'acceptInfo') {
      setErrors({ ...errors, acceptInfo: '' });
    }
    if (name === 'markSymbol') {
      setErrors({ ...errors, markSymbol: '' });
    }
    if (name === 'markNumber') {
      setErrors({ ...errors, markNumber: '' });
    }
    if (name === 'markClass') {
      setErrors({ ...errors, markClass: '' });
    }
    if (name === 'markOffice') {
      setErrors({ ...errors, markOffice: '' });
    }
    if (name === 'markLink') {
      setErrors({ ...errors, markLink: '' });
    }
    if (name === 'copyRightInfo') {
      setErrors({ ...errors, copyRightInfo: '' });
    }
    if (name === 'copyRightURL') {
      setErrors({ ...errors, copyRightURL: '' });
    }
    if (name === 'aggRegisteredType') {
      setErrors({ ...errors, aggRegisteredType: '' });
    }
    if (name === 'aggRegisteredName') {
      setErrors({ ...errors, aggRegisteredName: '' });
    }
    if (name === 'aggRegisteredUserName') {
      setErrors({ ...errors, aggRegisteredUserName: '' });
    }
    if (name === 'issueTitle') {
      setErrors({ ...errors, issueTitle: '' });
    }
  };

  useEffect(() => {
    open &&
      setTicket({
        registeredType: '0',
        registeredName: '',
        registeredUserName: '',
        registeredEmail: '',
        issueDetails: '',
        groupName: '',
        groupUserName: '',
        blockedName: '',
        // blockedUserName: "",
        // name: "",
        // username: "",
        // email: "",
        // phoneNumber: "",
        // phoneCountry: "",
        relationship: '',
        impersonatedName: '',
        impersonatedUsername: '',
        reportedName: '',
        reportedUsername: '',
        furtherDetails: '',
        bizName: '',
        bizAddress1: '',
        // bizAddress2: "",
        // bizTown: "",
        // bizState: "",
        // bizCountry: "",
        // bizPost: "",
        bizWebsite: '',
        bizUserName: '',
        bizWay: '',
        markSymbol: '',
        markNumber: '',
        markClass: '',
        markOffice: '',
        markLink: '',
        copyRightInfo: '',
        copyRightURL: '',
        aggRegisteredType: '0',
        aggRegisteredName: '',
        aggRegisteredUserName: '',
        issueTitle: '',
      });
    setViolations([]);
    setAcceptInfo(false);
    setErrors({});
    // eslint-disable-next-line
  }, [open]);

  const validation = () => {
    let error = {};
    let flag = true;
    let val = ticket;
    let emailregx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (
      category === 'sst1' ||
      category === 'sst2' ||
      category === 'sst3' ||
      category === 'sst4' ||
      category === 'sst7'
      // category === "sst36"
    ) {
      if (val?.registeredType === '') {
        error.registeredType = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.registeredName === '') {
        error.registeredName = localesData?.validation?.required_message;
        flag = false;
      }
      if (category !== 'sst3') {
        if (val?.registeredUserName === '') {
          error.registeredUserName = localesData?.validation?.required_message;
          flag = false;
        }
      }
      if (val?.registeredEmail === '') {
        error.registeredEmail = localesData?.validation?.required_message;
        flag = false;
      } else if (!emailregx.test(val?.registeredEmail)) {
        error.registeredEmail = localesData?.validation?.valid_email;
        flag = false;
      }
    }
    if (category === 'sst3' || category === 'sst7' || category === 'sst15') {
      if (val?.issueDetails === '') {
        error.issueDetails = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst9') {
      if (val?.registeredEmail === '') {
        error.registeredEmail = localesData?.validation?.required_message;
        flag = false;
      } else if (!emailregx.test(val?.registeredEmail)) {
        error.registeredEmail = localesData?.validation?.valid_email;
        flag = false;
      }
    }

    if (category === 'sst16') {
      if (val?.groupName === '') {
        error.groupName = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.groupUserName === '') {
        error.groupUserName = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst17') {
      if (val?.blockedName === '') {
        error.blockedName = localesData?.validation?.required_message;
        flag = false;
      }
      // if (val?.blockedUserName === "") {
      //   error.blockedUserName = localesData?.validation?.required_message;
      //   flag = false;
      // }
    }
    if (
      category === 'sst27' ||
      category === 'sst28' ||
      category === 'sst29' ||
      category === 'sst30' ||
      category === 'sst31' ||
      category === 'sst32'
    ) {
      // if (val?.name === "") {
      //   error.name = localesData?.validation?.required_message;
      //   flag = false;
      // }
      // if (val?.email === "") {
      //   error.email = localesData?.validation?.required_message;
      //   flag = false;
      // } else if (!emailregx.test(val?.email)) {
      //   error.email = localesData?.validation?.valid_email;
      //   flag = false;
      // }
      // if (val?.phoneNumber === "") {
      //   error.phoneNumber = localesData?.validation?.required_message;
      //   flag = false;
      // }
      // if (val?.phoneCountry === "") {
      //   error.phoneCountry = localesData?.validation?.required_message;
      //   flag = false;
      // }
      if (val?.relationship === '') {
        error.relationship = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst27') {
      if (val?.impersonatedName === '') {
        error.impersonatedName = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst28') {
      if (val?.bizWay === '') {
        error.bizWay = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (
      category === 'sst27' ||
      category === 'sst28' ||
      category === 'sst29' ||
      category === 'sst30' ||
      category === 'sst31' ||
      category === 'sst32' ||
      category === 'sst33' ||
      category === 'sst34'
    ) {
      if (val?.reportedName === '') {
        error.reportedName = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.reportedUsername === '') {
        error.reportedUsername = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.furtherDetails === '') {
        error.furtherDetails = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst28' || category === 'sst29' || category === 'sst30') {
      if (val?.bizName === '') {
        error.bizName = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.bizAddress1 === '') {
        error.bizAddress1 = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.bizTown === '') {
        error.bizTown = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.bizCountry === '') {
        error.bizCountry = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.bizPost === '') {
        error.bizPost = localesData?.validation?.required_message;
        flag = false;
      }
      if (category !== 'sst29' && category !== 'sst30') {
        if (val?.bizWebsite === '') {
          error.bizWebsite = localesData?.validation?.required_message;
          flag = false;
        }
      }
      if (!acceptInfo) {
        error.acceptInfo = localesData?.validation?.accept_terms;
        flag = false;
      }
    }
    if (category === 'sst29') {
      if (val?.registeredType === '') {
        error.registeredType = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.markSymbol === '') {
        error.markSymbol = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.markNumber === '') {
        error.markNumber = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.markClass === '') {
        error.markClass = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.markOffice === '') {
        error.markOffice = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.markLink === '') {
        error.markLink = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst30') {
      if (val?.copyRightInfo === '') {
        error.copyRightInfo = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.registeredType === '') {
        error.registeredType = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst31' || category === 'sst32') {
      if (val?.aggRegisteredType === '') {
        error.aggRegisteredType = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.aggRegisteredName === '') {
        error.aggRegisteredName = localesData?.validation?.required_message;
        flag = false;
      }
      if (category !== 'sst32') {
        if (val?.aggRegisteredUserName === '') {
          error.aggRegisteredUserName =
            localesData?.validation?.required_message;
          flag = false;
        }
      }
      if (category === 'sst32') {
        if (val?.registeredType === '') {
          error.registeredType = localesData?.validation?.required_message;
          flag = false;
        }
      }
    }
    if (category === 'sst33' || category === 'sst34') {
      if (val?.registeredType === '') {
        error.registeredType = localesData?.validation?.required_message;
        flag = false;
      }
    }
    if (category === 'sst36') {
      if (val?.furtherDetails === '') {
        error.furtherDetails = localesData?.validation?.required_message;
        flag = false;
      }
      if (val?.issueTitle === '') {
        error.issueTitle = localesData?.validation?.required_message;
        flag = false;
      }
    }
    setErrors(error);
    return flag;
  };

  // GET TICKETS
  const getTickets = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.ticketDetails + `?id=${category}`
      );
      if (status === 200) {
        setTicketData(data?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (category) {
      category !== 'sst18' &&
        category !== 'sst19' &&
        category !== 'sst20' &&
        category !== 'sst21' &&
        category !== 'sst23' &&
        category !== 'sst24' &&
        getTickets();
    }
    // eslint-disable-next-line
  }, [category]);

  const handleSubmit = async () => {
    if (validation()) {
      // setIsDisabled(true);
      // setLoader(true);
      let sendData = {
        category: ticketData?.category,
        issue_title:
          category === 'sst26'
            ? getIssue
              ? getIssue
              : siteFeature?.feature
            : category === 'sst36'
            ? ticket?.issueTitle
            : ticketData?.title,
        ticket_code: category,

        ...(category === 'sst1' ||
        category === 'sst2' ||
        category === 'sst3' ||
        category === 'sst4' ||
        category === 'sst7'
          ? {
              registeredType: ticket?.registeredType
                ? ticket?.registeredType
                : '',
              registeredName: ticket?.registeredName
                ? ticket?.registeredName
                : '',
              registeredUserName: ticket?.registeredUserName
                ? ticket?.registeredUserName
                : '',
              registeredEmail: ticket?.registeredEmail
                ? ticket?.registeredEmail
                : '',
            }
          : ''),
        ...(category === 'sst36'
          ? {
              // registeredType: ticket?.registeredType
              //   ? ticket?.registeredType
              //   : "",
              // name: ticket?.registeredName ? ticket?.registeredName : "",
              // userName: ticket?.registeredUserName
              //   ? ticket?.registeredUserName
              //   : "",
              // email: ticket?.registeredEmail ? ticket?.registeredEmail : "",
            }
          : ''),
        ...(category === 'sst3' ||
        category === 'sst7' ||
        category === 'sst15' ||
        category === 'sst26'
          ? {
              issueDetails:
                category === 'sst26'
                  ? getIssueDetails
                    ? getIssueDetails
                    : siteFeature?.issueDetails
                  : ticket?.issueDetails
                  ? ticket?.issueDetails
                  : '',
            }
          : ''),
        ...(category === 'sst9'
          ? {
              registeredEmailNew: ticket?.registeredEmail
                ? ticket?.registeredEmail
                : '',
            }
          : ''),
        ...(category === 'sst16'
          ? {
              groupName: ticket?.groupName ? ticket?.groupName : '',
              groupUserName: ticket?.groupUserName ? ticket?.groupUserName : '',
            }
          : ''),
        ...(category === 'sst17'
          ? {
              blockedName: ticket?.blockedName ? ticket?.blockedName : '',
              // blockedUserName: ticket?.blockedUserName
              //   ? ticket?.blockedUserName
              //   : "",
            }
          : ''),
        ...(category === 'sst26'
          ? {
              registeredType: loginUser ? loginUser?.user_type : '',
              registeredName: loginUser ? loginUser?.name : '',
              registeredUserName: loginUser ? loginUser?.user_name : '',
              registeredEmail: loginUser ? loginUser?.email : '',
            }
          : ''),
        ...(category === 'sst27' ||
        category === 'sst28' ||
        category === 'sst29' ||
        category === 'sst30' ||
        category === 'sst31' ||
        category === 'sst32'
          ? {
              // name: ticket?.name ? ticket?.name : "",
              // userName: ticket?.username ? ticket?.username : "",
              // email: ticket?.email ? ticket?.email : "d",
              // phoneNumber: ticket?.phoneNumber ? ticket?.phoneNumber : "",
              // phoneCountry: ticket?.phoneCountry ? ticket?.phoneCountry : "",
              relationship: ticket?.relationship ? ticket?.relationship : '',
            }
          : ''),
        ...(category === 'sst31' || category === 'sst32'
          ? {
              aggrievedType: ticket?.aggRegisteredType
                ? ticket?.aggRegisteredType
                : '',
              aggrievedName: ticket?.aggRegisteredName
                ? ticket?.aggRegisteredName
                : '',
              aggrievedUserName: ticket?.aggRegisteredUserName
                ? ticket?.aggRegisteredUserName
                : '',
            }
          : ''),
        ...(category === 'sst27' ||
        category === 'sst28' ||
        category === 'sst29' ||
        category === 'sst30' ||
        category === 'sst31' ||
        category === 'sst32' ||
        category === 'sst33' ||
        category === 'sst34'
          ? {
              reportedName: ticket?.reportedName ? ticket?.reportedName : '',
              reportedUserName: ticket?.reportedUsername
                ? ticket?.reportedUsername
                : '',
              furtherDetails: ticket?.furtherDetails
                ? ticket?.furtherDetails
                : '',
              ...(category === 'sst27'
                ? {
                    impersonatedName: ticket?.impersonatedName
                      ? ticket?.impersonatedName
                      : '',
                    impersonatedUserName: ticket?.impersonatedUsername
                      ? ticket?.impersonatedUsername
                      : '',
                  }
                : ''),
            }
          : ''),
        ...(category === 'sst32' || category === 'sst33' || category === 'sst34'
          ? {
              reportedType: ticket?.registeredType
                ? ticket?.registeredType
                : '',
            }
          : ''),
        ...(category === 'sst28' || category === 'sst29' || category === 'sst30'
          ? {
              bizName: ticket?.bizName ? ticket?.bizName : '',
              bizAddress1: ticket?.bizAddress1 ? ticket?.bizAddress1 : '',
              // bizAddress2: ticket?.bizAddress2 ? ticket?.bizAddress2 : "",
              // bizTown: ticket?.bizTown ? ticket?.bizTown : "sd",
              // bizState: ticket?.bizState ? ticket?.bizState : "",
              // bizCountry: ticket?.bizCountry ? ticket?.bizCountry : "sdfs",
              // bizPost: ticket?.bizPost ? ticket?.bizPost : "sds",
              bizWebsite: ticket?.bizWebsite ? ticket?.bizWebsite : '',
              bizUserName: ticket?.bizUserName ? ticket?.bizUserName : '',
              ...(category === 'sst28'
                ? {
                    bizWay: ticket?.bizWay ? ticket?.bizWay : '',
                  }
                : ''),
              ...(category === 'sst29'
                ? {
                    markSymbol: ticket?.markSymbol ? ticket?.markSymbol : '',
                    markNumber: ticket?.markNumber ? ticket?.markNumber : '',
                    markClass: ticket?.markClass ? ticket?.markClass : '',
                    markOffice: ticket?.markOffice ? ticket?.markOffice : '',
                    markLink: ticket?.markLink ? ticket?.markLink : '',
                    registeredType: ticket?.registeredType
                      ? ticket?.registeredType
                      : '',
                  }
                : ''),
            }
          : ''),
        ...(category === 'sst30'
          ? {
              copyrightDetails: ticket?.copyRightInfo
                ? ticket?.copyRightInfo
                : '',
              copyrightUrl: ticket?.copyRightURL ? ticket?.copyRightURL : '',
            }
          : ''),
        ...(category === 'sst36'
          ? {
              furtherDetails: ticket?.furtherDetails
                ? ticket?.furtherDetails
                : '',
            }
          : ''),
        ...(category === 'sst31' || category === 'sst32' || category === 'sst33'
          ? { reportedViolations: violations ? violations : [] }
          : ''),
      };
      let userCheckID = userID ? `?user_id=${userID}` : '';
      try {
        const { status, data } = await axiosInstance.post(
          URLS.ticketDetails + userCheckID,
          sendData
        );
        if (status === 200) {
          if (data?.status) {
            setApiMessage('success', data?.message);
            setTicket('');
            handleClose();
            setSiteData({});
          } else {
            setApiMessage('error', data?.message);
          }
          setIsDisabled(false);
          setLoader(false);
        }
      } catch (error) {
        setApiMessage('error', error?.response?.data?.message);
        setIsDisabled(false);
        setLoader(false);
      }
    }
  };

  return (
    <Box className='dailog-support-ticket'>
      <DialogBox
        // closeIcon={closeIcon}
        handleClose={() => handleClose()}
        open={open}
        title={title}
        content={
          <Box>
            {loader && <PreLoader />}
            <Grid container className='main-grid-container main-section-block'>
              <Grid item xs={12} sm={12} md={12} lg={12} className=''>
                {(sst1 ||
                  sst2 ||
                  sst3 ||
                  sst4 ||
                  sst7 ||
                  sst8 ||
                  sst9 ||
                  sst10 ||
                  sst11 ||
                  sst12 ||
                  sst13 ||
                  sst15 ||
                  sst16 ||
                  sst17 ||
                  sst26 ||
                  sst27 ||
                  sst28 ||
                  sst29 ||
                  sst30 ||
                  sst31 ||
                  sst32 ||
                  sst33 ||
                  sst34 ||
                  sst36) &&
                category !== 'sst18' &&
                category !== 'sst19' &&
                category !== 'sst20' &&
                category !== 'sst21' &&
                category !== 'sst23' &&
                category !== 'sst24' ? (
                  <Box className='plateform_top'>
                    <Typography
                      variant='h5'
                      className='mb-17'
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.contact
                            ?.ticket_desc1,
                      }}
                    />
                    <Typography
                      variant='h5'
                      className='mb-17'
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.contact
                            ?.ticket_desc2,
                      }}
                    />
                    <Box>
                      {/* CATEGORY */}
                      <Grid container className='support_wrap'>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormHelperText className='custom-field-helper'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.support_category
                            }
                            :
                          </FormHelperText>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={7}
                          lg={7}
                          className='reg_text'
                        >
                          <Typography variant='h5'>
                            {ticketData?.category}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* ISSUE */}
                      <Grid container className='support_wrap'>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormHelperText className='custom-field-helper'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.support_issue
                            }
                            :
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <Typography variant='h5' className='reg_text'>
                            {category === 'sst26' ? (
                              getIssue ? (
                                getIssue
                              ) : (
                                siteFeature?.feature
                              )
                            ) : category === 'sst36' ? (
                              <CustomTextField
                                error={Boolean(errors.issueTitle)}
                                helperText={errors.issueTitle}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='issueTitle'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.issueTitle || ''}
                              />
                            ) : (
                              ticketData?.title
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      {category === 'sst26' && (
                        <>
                          {/* ISSUE DETAILS */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.issue_details
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Typography variant='h5' className=''>
                                {getIssueDetails
                                  ? getIssueDetails
                                  : siteFeature?.issueDetails}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {/* TICKET DATE */}
                      <Grid container className='support_wrap'>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormHelperText className='custom-field-helper'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.ticket_date
                            }
                            :
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <Typography variant='h5'>
                            {moment
                              .utc(new Date())
                              .local()
                              .format('dddd, MMMM DD, YYYY')}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* TICKET TIME */}
                      <Grid container className='support_wrap'>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormHelperText className='custom-field-helper'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.ticket_time
                            }
                            :
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <Typography variant='h5'>
                            {moment.utc(new Date()).local().format('hh:mm a')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className='divider mb-17' />
                      {/* {(category === "sst33" || category === "sst34") && (
                        <Typography variant="h4" className="u-line mb-17">
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.your_details
                          }
                        </Typography>
                      )} */}
                      {(category === 'sst1' ||
                        category === 'sst2' ||
                        category === 'sst3' ||
                        category === 'sst4' ||
                        category === 'sst7') && (
                        // category === "sst36"
                        <>
                          {category === 'sst36' && (
                            <Typography variant='h4' className='u-line mb-17'>
                              {
                                localesData?.info_content?.help_center?.contact
                                  ?.your_details
                              }
                            </Typography>
                          )}
                          {/* TYPE */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_user_type
                                }
                                :{/* <span className="valid_star">*</span> */}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              {/* <Select
                                error={errors.registeredType ? true : false}
                                fullWidth
                                value={ticket?.registeredType || ""}
                                onChange={(e) => handleChange(e)}
                                input={<CustomSelectInput />}
                                className="custom-select"
                                name="registeredType"
                                IconComponent={DropdownIcon}
                                displayEmpty
                              >
                                <MenuItem value="">
                                  {localesData?.select}
                                </MenuItem>
                                {userType?.map((item, i) => (
                                  <MenuItem value={item?.value} key={i}>
                                    {item?.type}
                                  </MenuItem>
                                ))}
                              </Select> */}
                              <Typography variant='h5'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.ind
                                }
                              </Typography>
                              {/* {errors.registeredType && (
                                <FormHelperText
                                  error={errors.registeredType ? true : false}
                                >
                                  {errors.registeredType}
                                </FormHelperText>
                              )} */}
                            </Grid>
                          </Grid>
                          {/* NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst36'
                                  ? localesData?.user_account?.name
                                  : localesData?.info_content?.help_center
                                      ?.contact?.registered_name}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.registeredName)}
                                helperText={errors.registeredName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='registeredName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.registeredName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* USER NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst36'
                                  ? localesData?.sezzyou_username
                                  : localesData?.info_content?.help_center
                                      ?.contact?.registered_username}
                                :
                                {category !== 'sst3' && (
                                  <span className='valid_star'>*</span>
                                )}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.registeredUserName)}
                                helperText={errors.registeredUserName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='registeredUserName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.registeredUserName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* EMAIL */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst36'
                                  ? localesData?.register?.your_email_address
                                  : localesData?.info_content?.help_center
                                      ?.contact?.registered_email}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.registeredEmail)}
                                helperText={errors.registeredEmail}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='registeredEmail'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.registeredEmail || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst8' ||
                      category === 'sst9' ||
                      category === 'sst10' ||
                      category === 'sst11' ||
                      category === 'sst12' ||
                      category === 'sst13' ||
                      category === 'sst15' ||
                      category === 'sst16' ||
                      category === 'sst17' ||
                      category === 'sst26' ||
                      category === 'sst33' ||
                      category === 'sst30' ||
                      category === 'sst29' ||
                      category === 'sst31' ||
                      category === 'sst32' ||
                      category === 'sst36' ||
                      category === 'sst27' ||
                      category === 'sst34' ? (
                        <>
                          {/* USER TYPE */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_user_type
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Typography variant='h5'>
                                {loginUser?.user_type}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_name
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Typography variant='h5'>
                                {loginUser?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* USERNAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_username
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Typography variant='h5'>
                                {loginUser?.user_name}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* EMAIL */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_email
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Typography variant='h5'>
                                {loginUser?.email}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                      {category === 'sst9' && (
                        <>
                          {/* EMAIL */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.new_email
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.registeredEmail)}
                                helperText={errors.registeredEmail}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='registeredEmail'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.registeredEmail || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst28' ||
                        category === 'sst29' ||
                        category === 'sst30' ||
                        category === 'sst27' ||
                        category === 'sst31' ||
                        category === 'sst32') && (
                        <>
                          {/* <Typography variant="h4" className="u-line mb-17">
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.your_details
                            }
                          </Typography> */}
                          {/* NAME */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.user_account?.name}:
                                <span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="name"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.name || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* USERNAME */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.sezzyou_username}:
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.username)}
                                helperText={errors.username}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="username"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.username || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* EMAIL */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.register?.your_email_address}:
                                <span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="email"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.email || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* PHONE NUMBER */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.phone_number
                                }
                                :<span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.phoneNumber)}
                                helperText={errors.phoneNumber}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="phoneNumber"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.phoneNumber || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* PHONE COUNTRY */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.phone_country
                                }
                                :<span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.phoneCountry)}
                                helperText={errors.phoneCountry}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="phoneCountry"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.phoneCountry || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* RELATIONSHIP */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst28'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.relationship_business
                                  : category === 'sst29'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.relationship_trademark
                                  : category === 'sst30'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.relationship_copyright
                                  : category === 'sst31'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.relationship_aggrieved
                                  : category === 'sst32'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.relationship_aggrieved_person
                                  : localesData?.info_content?.help_center
                                      ?.contact?.relationship_person}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.relationship)}
                                helperText={errors.relationship}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='relationship'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.relationship || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst31' || category === 'sst32') && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {category === 'sst32'
                              ? localesData?.info_content?.help_center?.contact
                                  ?.aggrieved_person
                              : localesData?.info_content?.help_center?.contact
                                  ?.aggrieved_sezzyou_user}
                          </Typography>
                          {/* TYPE */}
                          {category !== 'sst32' && (
                            <Grid container className='support_wrap'>
                              <Grid item xs={12} sm={12} md={5} lg={5}>
                                <FormHelperText className='custom-field-helper'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.registered_user_type
                                  }
                                  :{/* <span className="valid_star">*</span> */}
                                </FormHelperText>
                              </Grid>
                              <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Typography variant='h5'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.ind
                                  }
                                </Typography>
                                {/* <Select
                                error={errors.aggRegisteredType ? true : false}
                                fullWidth
                                value={ticket?.aggRegisteredType || ""}
                                onChange={(e) => handleChange(e)}
                                input={<CustomSelectInput />}
                                className="custom-select"
                                name="aggRegisteredType"
                                IconComponent={DropdownIcon}
                                displayEmpty
                              >
                                <MenuItem value="">
                                  {localesData?.select}
                                </MenuItem>
                                {userType?.map((item, i) => (
                                  <MenuItem value={item?.value} key={i}>
                                    {item?.type}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.aggRegisteredType && (
                                <FormHelperText
                                  error={
                                    errors.aggRegisteredType ? true : false
                                  }
                                >
                                  {errors.aggRegisteredType}
                                </FormHelperText>
                              )} */}
                              </Grid>
                            </Grid>
                          )}
                          {/* NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst32'
                                  ? localesData?.user_account?.name
                                  : localesData?.info_content?.help_center
                                      ?.contact?.registered_name}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.aggRegisteredName)}
                                helperText={errors.aggRegisteredName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='aggRegisteredName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.aggRegisteredName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* USER NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst32'
                                  ? localesData?.sezzyou_username
                                  : localesData?.info_content?.help_center
                                      ?.contact?.registered_username}
                                :
                                {category !== 'sst32' && (
                                  <span className='valid_star'>*</span>
                                )}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.aggRegisteredUserName)}
                                helperText={errors.aggRegisteredUserName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='aggRegisteredUserName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.aggRegisteredUserName || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst27' && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.impersonated_person
                            }
                          </Typography>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {localesData?.user_account?.name}:
                                <span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.impersonatedName)}
                                helperText={errors.impersonatedName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='impersonatedName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.impersonatedName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* USERNAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {localesData?.sezzyou_username}:
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.impersonatedUsername)}
                                helperText={errors.impersonatedUsername}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='impersonatedUsername'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.impersonatedUsername || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst28' ||
                        category === 'sst29' ||
                        category === 'sst30') && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {category === 'sst28'
                              ? localesData?.info_content?.help_center?.contact
                                  ?.impersonated_business
                              : category === 'sst30'
                              ? localesData?.info_content?.help_center?.contact
                                  ?.copyright_owner
                              : localesData?.info_content?.help_center?.contact
                                  ?.trademark_holder}
                          </Typography>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst28'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.business_name
                                  : localesData?.user_account?.name}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizName)}
                                helperText={errors.bizName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='bizName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* <FormHelperText className="custom-field-helper">
                            {category === "sst28"
                              ? localesData?.info_content?.help_center?.contact
                                  ?.business_address
                              : localesData?.info_content?.help_center?.contact
                                  ?.address}
                            :
                          </FormHelperText> */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              {/* <FormHelperText className="custom-field-helper">
                                {localesData?.register?.address1}:
                                <span className="valid_star">*</span>
                              </FormHelperText> */}
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst28'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.business_address
                                  : localesData?.info_content?.help_center
                                      ?.contact?.address}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizAddress1)}
                                helperText={errors.bizAddress1}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='bizAddress1'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizAddress1 || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.register?.address2}:
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizAddress2)}
                                helperText={errors.bizAddress2}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="bizAddress2"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizAddress2 || ""}
                              />
                            </Grid>
                          </Grid> */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.register?.town}:
                                <span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizTown)}
                                helperText={errors.bizTown}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="bizTown"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizTown || ""}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.state
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizState)}
                                helperText={errors.bizState}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="bizState"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizState || ""}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.country}:
                                <span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizCountry)}
                                helperText={errors.bizCountry}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="bizCountry"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizCountry || ""}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {localesData?.register?.post_code}:
                                <span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizPost)}
                                helperText={errors.bizPost}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="bizPost"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizPost || ""}
                              />
                            </Grid>
                          </Grid> */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {localesData?.register?.website}:
                                {category !== 'sst29' &&
                                  category !== 'sst30' && (
                                    <span className='valid_star'>*</span>
                                  )}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizWebsite)}
                                helperText={errors.bizWebsite}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='bizWebsite'
                                onChange={(e) => handleChange(e)}
                                value={
                                  ticket?.bizWebsite?.replace(/\s+/g, '') || ''
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {localesData?.sezzyou_username}:
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.bizUserName)}
                                helperText={errors.bizUserName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='bizUserName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.bizUserName || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst29' && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.trademark_information
                            }
                          </Typography>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.trademarked_word_symbol
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.markSymbol)}
                                helperText={errors.markSymbol}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='markSymbol'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.markSymbol || ''}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registration_number
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.markNumber)}
                                helperText={errors.markNumber}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='markNumber'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.markNumber || ''}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.trademark_classes
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.markClass)}
                                helperText={errors.markClass}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='markClass'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.markClass || ''}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registration_office
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.markOffice)}
                                helperText={errors.markOffice}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='markOffice'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.markOffice || ''}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.trademark_record_link
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.markLink)}
                                helperText={errors.markLink}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='markLink'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.markLink || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst30' && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.copyright_information
                            }
                          </Typography>
                          <Typography variant='h5' className='mb-17'>
                            {
                              localesData?.info_content?.help_center?.contact
                                ?.copyright_desc1
                            }
                          </Typography>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <CustomTextField
                                error={Boolean(errors.copyRightInfo)}
                                helperText={errors.copyRightInfo}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield custom-multiline'
                                name='copyRightInfo'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.copyRightInfo || ''}
                                multiline
                                minRows={4}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.copyright_url
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.copyRightURL)}
                                helperText={errors.copyRightURL}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='copyRightURL'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.copyRightURL || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst27' ||
                        category === 'sst28' ||
                        category === 'sst29' ||
                        category === 'sst30' ||
                        category === 'sst31' ||
                        category === 'sst32' ||
                        category === 'sst33' ||
                        category === 'sst34') && (
                        <>
                          <Divider className='divider mb-17' />
                          <Typography variant='h4' className='u-line mb-17'>
                            {category === 'sst29' || category === 'sst30'
                              ? localesData?.info_content?.help_center?.contact
                                  ?.reported_user_infringement
                              : localesData?.info_content?.help_center?.contact
                                  ?.reported_user}
                          </Typography>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_user_type
                                }
                                :{/* <span className="valid_star">*</span> */}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Box>
                                {category === 'sst28' ? (
                                  localesData?.info_content?.help_center
                                    ?.contact?.group
                                ) : category === 'sst29' ||
                                  category === 'sst30' ||
                                  category === 'sst32' ||
                                  category === 'sst33' ||
                                  category === 'sst34' ? (
                                  <>
                                    <Typography variant='h5'>
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.ind
                                      }
                                    </Typography>
                                    {/* <Select
                                      error={
                                        errors.registeredType ? true : false
                                      }
                                      fullWidth
                                      value={ticket?.registeredType || ""}
                                      onChange={(e) => handleChange(e)}
                                      input={<CustomSelectInput />}
                                      className="custom-select"
                                      name="registeredType"
                                      IconComponent={DropdownIcon}
                                      displayEmpty
                                    >
                                      <MenuItem value="">
                                        {localesData?.select}
                                      </MenuItem>
                                      {userType?.map((item, i) => (
                                        <MenuItem value={item?.value} key={i}>
                                          {item?.type}
                                        </MenuItem>
                                      ))}
                                      {(category === "sst29" ||
                                        category === "sst30" ||
                                        category === "sst32") && (
                                        <MenuItem value="2">
                                          {
                                            localesData?.info_content
                                              ?.help_center?.contact?.advertiser
                                          }
                                        </MenuItem>
                                      )}
                                    </Select>
                                    {errors.registeredType && (
                                      <FormHelperText
                                        error={
                                          errors.registeredType ? true : false
                                        }
                                      >
                                        {errors.registeredType}
                                      </FormHelperText>
                                    )} */}
                                  </>
                                ) : (
                                  localesData?.info_content?.help_center
                                    ?.contact?.individual
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_name
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.reportedName)}
                                helperText={errors.reportedName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='reportedName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.reportedName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* USERNAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.registered_username
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.reportedUsername)}
                                helperText={errors.reportedUsername}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='reportedUsername'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.reportedUsername || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst28' && (
                        <>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.way_business
                                }
                                <span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <Autocomplete
                                options={wayBusiness}
                                getOptionLabel={(option) => option?.type || ''}
                                value={
                                  wayBusiness?.find((item) => {
                                    return item?.value === ticket?.bizWay;
                                  }) || ''
                                }
                                onChange={(e, newValue) => {
                                  handleChangeSelect(
                                    'bizWay',
                                    newValue?.value ? newValue?.value : ''
                                  );
                                }}
                                renderTags={() => null}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                    className='custom-textfield autocomplete-field'
                                    placeholder={localesData?.select}
                                  />
                                )}
                                popupIcon={<DropdownIcon />}
                              />
                              {/* <Select
                                error={errors.bizWay ? true : false}
                                fullWidth
                                value={ticket?.bizWay || ''}
                                onChange={(e) => handleChange(e)}
                                input={<CustomSelectInput />}
                                className='custom-select'
                                name='bizWay'
                                IconComponent={DropdownIcon}
                                displayEmpty
                              >
                                <MenuItem value=''>
                                  {localesData?.select}
                                </MenuItem>
                                {wayBusiness?.map((item, i) => (
                                  <MenuItem value={item?.value} key={i}>
                                    {item?.type}
                                  </MenuItem>
                                ))}
                              </Select> */}
                              {errors.bizWay && (
                                <FormHelperText
                                  error={errors.bizWay ? true : false}
                                >
                                  {errors.bizWay}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst3' ||
                        category === 'sst7' ||
                        category === 'sst15') && (
                        <>
                          {/* ISSUE DETAILS */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.issue_details
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.issueDetails)}
                                helperText={errors.issueDetails}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield custom-multiline'
                                name='issueDetails'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.issueDetails || ''}
                                multiline
                                minRows={4}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst31' ||
                        category === 'sst32' ||
                        category === 'sst33') && (
                        <>
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.violations
                                }
                                :
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              {category === 'sst31' ? (
                                <>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='harassment'
                                      checked={violations.includes(
                                        'harassment'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('harassment')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.harassment
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='abuse'
                                      checked={violations.includes('abuse')}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('abuse')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.abuse
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='threats'
                                      checked={violations.includes('threats')}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('threats')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.threats
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='bullying'
                                      checked={violations.includes('bullying')}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('bullying')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.bullying
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='intimidation'
                                      checked={violations.includes(
                                        'intimidation'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('intimidation')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.intimidation
                                      }
                                    </Typography>
                                  </Box>
                                </>
                              ) : category === 'sst32' ? (
                                <>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Divulged Private Contact Information'
                                      checked={violations.includes(
                                        'Divulged Private Contact Information'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Divulged Private Contact Information'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.divulged_contact_info
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Divulged Private Financial Information'
                                      checked={violations.includes(
                                        'Divulged Private Financial Information'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Divulged Private Financial Information'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.divulged_financial_info
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Divulged Private Government Identification Information'
                                      checked={violations.includes(
                                        'Divulged Private Government Identification Information'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Divulged Private Government Identification Information'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.divulged_gov_info
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Uploaded Private Photograph'
                                      checked={violations.includes(
                                        'Uploaded Private Photograph'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Uploaded Private Photograph'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.uploaded_photograph
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Uploaded Private Video'
                                      checked={violations.includes(
                                        'Uploaded Private Video'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Uploaded Private Video'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.uploaded_video
                                      }
                                    </Typography>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Obscene Content'
                                      checked={violations.includes(
                                        'Obscene Content'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('Obscene Content')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.obscene_content
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Pornographic Content'
                                      checked={violations.includes(
                                        'Pornographic Content'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox(
                                          'Pornographic Content'
                                        )
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.pornographic_content
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className='accept-info-checkbox'>
                                    <Checkbox
                                      icon={<UncheckIcon />}
                                      checkedIcon={<CheckIcon />}
                                      value='Offensive Content'
                                      checked={violations.includes(
                                        'Offensive Content'
                                      )}
                                      name='violations'
                                      onChange={(e) => handleChange(e)}
                                      className=''
                                    />
                                    <Typography
                                      variant='h5'
                                      className='pointer'
                                      style={{ userSelect: 'none' }}
                                      onClick={() =>
                                        handleClickCheckBox('Offensive Content')
                                      }
                                    >
                                      {
                                        localesData?.info_content?.help_center
                                          ?.contact?.offensive_content
                                      }
                                    </Typography>
                                  </Box>
                                </>
                              )}
                              {/* <Typography variant="h5">
                                {category === "sst31"
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.ticket_violation_desc1
                                  : category === "sst32"
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.ticket_violation_desc2
                                  : localesData?.info_content?.help_center
                                      ?.contact?.ticket_violation_desc3}
                              </Typography> */}
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst27' ||
                        category === 'sst28' ||
                        category === 'sst29' ||
                        category === 'sst30' ||
                        category === 'sst31' ||
                        category === 'sst32' ||
                        category === 'sst33' ||
                        category === 'sst34' ||
                        category === 'sst36') && (
                        <>
                          {category === 'sst36' && (
                            <>
                              <Divider className='divider mb-17' />
                              <Typography variant='h4' className='u-line mb-17'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.issue_details
                                }
                              </Typography>
                            </>
                          )}
                          {/* FURTHER DETAILS */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {category === 'sst29' || category === 'sst30'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.infringement_details
                                  : localesData?.info_content?.help_center
                                      ?.contact?.further_details}
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              {(category === 'sst31' ||
                                category === 'sst32' ||
                                category === 'sst33') && (
                                <Typography variant='h5' className='mb-17'>
                                  {category === 'sst31'
                                    ? localesData?.info_content?.help_center
                                        ?.contact?.ticket_violation_desc1
                                    : category === 'sst32'
                                    ? localesData?.info_content?.help_center
                                        ?.contact?.ticket_violation_desc2
                                    : localesData?.info_content?.help_center
                                        ?.contact?.ticket_violation_desc3}
                                </Typography>
                              )}
                              {category === 'sst29' ? (
                                <Typography variant='h5' className='mb-17'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.infringement_desc1
                                  }
                                </Typography>
                              ) : category === 'sst30' ? (
                                <Typography variant='h5' className='mb-17'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.infringement_desc2
                                  }
                                </Typography>
                              ) : category === 'sst34' ? (
                                <Typography variant='h5' className='mb-17'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.infringement_desc3
                                  }
                                </Typography>
                              ) : category === 'sst36' ? (
                                <Typography variant='h5' className='mb-17'>
                                  {
                                    localesData?.info_content?.help_center
                                      ?.contact?.infringement_desc4
                                  }
                                </Typography>
                              ) : null}
                              <CustomTextField
                                error={Boolean(errors.furtherDetails)}
                                helperText={errors.furtherDetails}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield custom-multiline'
                                name='furtherDetails'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.furtherDetails || ''}
                                multiline
                                minRows={4}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {(category === 'sst28' ||
                        category === 'sst29' ||
                        category === 'sst30') && (
                        <>
                          <Grid container className='support_wrap'>
                            <Box className='accept-info-checkbox'>
                              <Checkbox
                                icon={<UncheckIcon />}
                                checkedIcon={<CheckIcon />}
                                value={acceptInfo}
                                checked={acceptInfo}
                                name='acceptInfo'
                                onChange={(e) => handleChange(e)}
                                className=''
                              />
                              <Typography
                                variant='h5'
                                className={
                                  errors?.acceptInfo
                                    ? 'reg_text pointer'
                                    : 'pointer'
                                }
                                style={{ userSelect: 'none' }}
                                onClick={() => setAcceptInfo(!acceptInfo)}
                              >
                                {category === 'sst28'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.checkbox_desc1
                                  : category === 'sst30'
                                  ? localesData?.info_content?.help_center
                                      ?.contact?.checkbox_desc2
                                  : localesData?.info_content?.help_center
                                      ?.contact?.checkbox_desc3}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                      {category === 'sst16' && (
                        <>
                          {/* GROUP NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {localesData?.register?.group_name}:
                                <span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.groupName)}
                                helperText={errors.groupName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='groupName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.groupName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* GROUP USERNAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.group_username
                                }
                                :<span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.groupUserName)}
                                helperText={errors.groupUserName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='groupUserName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.groupUserName || ''}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {category === 'sst17' && (
                        <>
                          {/* GROUP NAME */}
                          <Grid container className='support_wrap'>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className='custom-field-helper'>
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.blocked_name
                                }
                                <span className='valid_star'>*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.blockedName)}
                                helperText={errors.blockedName}
                                fullWidth
                                variant='outlined'
                                className='custom-textfield'
                                name='blockedName'
                                onChange={(e) => handleChange(e)}
                                value={ticket?.blockedName || ''}
                              />
                            </Grid>
                          </Grid>
                          {/* GROUP USERNAME */}
                          {/* <Grid container className="support_wrap">
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormHelperText className="custom-field-helper">
                                {
                                  localesData?.info_content?.help_center
                                    ?.contact?.blocked_username
                                }
                                :<span className="valid_star">*</span>
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <CustomTextField
                                error={Boolean(errors.blockedUserName)}
                                helperText={errors.blockedUserName}
                                fullWidth
                                variant="outlined"
                                className="custom-textfield"
                                name="blockedUserName"
                                onChange={(e) => handleChange(e)}
                                value={ticket?.blockedUserName || ""}
                              />
                            </Grid>
                          </Grid> */}
                        </>
                      )}
                      <Box
                        textAlign='center'
                        className='mb-17 support-btn-wrap'
                      >
                        <Button
                          className='btn-blue support-btn'
                          disabled={isDisabled}
                          onClick={handleSubmit}
                        >
                          {
                            localesData?.info_content?.help_center?.contact
                              ?.submit_ticket
                          }
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : category === 'sst18' ||
                  category === 'sst19' ||
                  category === 'sst20' ||
                  category === 'sst21' ||
                  category === 'sst23' ||
                  category === 'sst24' ? (
                  <Box className='plateform_top group-content'>
                    <Typography variant='h5' className='mb-17'>
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.ticket_grp_desc1
                      }
                      <Link to={`${userRoute}sign-in`} className='link_text'>
                        {localesData?.welcome?.login}
                      </Link>
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.ticket_grp_desc2
                      }
                    </Typography>
                  </Box>
                ) : (
                  <Box className='plateform_top group-content'>
                    <Typography variant='h5' className='mb-17'>
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.ticket_ind_desc1
                      }
                      <Link to={`${userRoute}sign-in`} className='link_text'>
                        {localesData?.welcome?.login}
                      </Link>
                      {
                        localesData?.info_content?.help_center?.contact
                          ?.ticket_ind_desc2
                      }
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        }
      />
    </Box>
  );
};

export default SpportModel;
