import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import PlatformFunc from "src/views/component/application/Info/Help/SiteFunction/platformFunc";
import { IntlContext } from "src/App";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const PlatformFuncsPage = () => {
  const classes = useStyles();
  const intlContext = React.useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Page
      className={classes.root}
      title={localesData.titles.app.sezz_you_help_center}
      description={localesData?.titles?.app?.welcome_desc}
    >
      <PlatformFunc />
    </Page>
  );
};

export default PlatformFuncsPage;
