import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "../../info.scss";

const Platform = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  // let dateAsString = new Date().toString().match(/\(([^)]+)\)$/)[1];
  // let timezone = dateAsString.match(/\b(\w)/g).join("");


  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head">
              {localesData?.info_content?.info?.title}
            </Typography>
            <Typography variant="h5">
              <span className="color-medium-gray">
                {localesData?.info_content?.info?.desc_title}
              </span>
              <Box className="plateform_top_inner">
                <ol className="number_row">
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_1,
                        }}
                      />
                    </li>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_2,
                        }}
                      />
                    </li>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_3,
                        }}
                      />
                    </li>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_4,
                        }}
                      />
                    </li>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_5,
                        }}
                      />
                    </li>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localesData?.info_content?.info?.line1_6,
                        }}
                      />
                    </li>
                  </ul>
                </ol>
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Platform;
