import React from 'react';
import { Navigate } from 'react-router-dom';

import { Config } from './helpers/context';

// LAYOUT
import AuthLayout from 'src/layouts/AuthLayout';
import MainLayout from 'src/layouts/MainLayout';
import InfoLayout from 'src/layouts/InfoLayout';
import AdvLayout from './layouts/AdvLayout';
import AdvAuthLayout from 'src/layouts/AdvAuthLayout';
// APPLICATION

//INFO
import PlatformPage from './views/pages/application/info/AboutSezzYou/infoPlatform';
import SitePage from './views/pages/application/info/AboutSezzYou/infoSite';
import PromisePage from './views/pages/application/info/AboutSezzYou/infoPromise';
import HelpPage from './views/pages/application/info/Help';
import UserAggreementPage from './views/pages/application/info/UserAggreement';
import PrivacyPolicyPage from './views/pages/application/info/PrivacyPolicy';
import SezzYouAppPage from './views/pages/application/info/SezzYouApp/SezzYouApp';
import AnnouncementsPage from './views/pages/application/info/Announcements/Announcements';
import GettingStartedPage from './views/pages/application/info/GettingStarted/GettingStarted';
import HomeInfoPage from './views/pages/application/info/HomePage/HomeInfo';
import TrendingInfoPage from './views/pages/application/info/TrendingInfo';
import PlateformNotiPage from './views/pages/application/info/PlateformNoti';
import VerifiedUserPage from './views/pages/application/info/VerifiedUser';
import YourAccountPage from './views/pages/application/info/SezzYouAcc/YourAccount';
import YourSectionPage from './views/pages/application/info/SezzMeWeAcc/YourSection';
import PlatformFuncPage from './views/pages/application/info/platformFunc';
import ContactSupportPage from './views/pages/application/info/ContactSupport';

// AUTHENTICATION
const WelcomeView = React.lazy(() => import('src/views/pages/auth/welcome'));
const LoginView = React.lazy(() => import('src/views/pages/auth/login'));
const IndividualRegisterView = React.lazy(() =>
  import('src/views/pages/auth/individualRegister')
);
const ConfirmationView = React.lazy(() =>
  import('./views/pages/auth/confirmation')
);
const ForgotPasswordView = React.lazy(() =>
  import('src/views/pages/auth/forgotPassword')
);
const ResetPassword = React.lazy(() =>
  import('src/views/pages/auth/resetPassword')
);
const ResetPassConfirmationView = React.lazy(() =>
  import('src/views/pages/auth/resetPassConfirmation')
);
const TermsView = React.lazy(() => import('src/views/pages/auth/terms'));
const EmailConfirmView = React.lazy(() =>
  import('./views/pages/auth/emailConfirm')
);

// USER
const Home = React.lazy(() => import('./views/pages/application/home'));
const UserProfile = React.lazy(() =>
  import('./views/pages/application/userprofile')
);
const Password = React.lazy(() => import('./views/pages/application/password'));
const Interests = React.lazy(() =>
  import('./views/pages/application/interests')
);
const EmailNotification = React.lazy(() =>
  import('./views/pages/application/emailNotification')
);
const SiteNotification = React.lazy(() =>
  import('./views/pages/application/siteNotification')
);
const OtherSettings = React.lazy(() =>
  import('./views/pages/application/otherSettings')
);
const UserBlocked = React.lazy(() =>
  import('./views/pages/application/userBlocked')
);
const VerifiedStatus = React.lazy(() =>
  import('./views/pages/application/verifiedStatus')
);
const ChangeName = React.lazy(() =>
  import('./views/pages/application/changeName')
);
const Listenerspage = React.lazy(() =>
  import('./views/pages/application/SezzMe/Listeners')
);
const Notification = React.lazy(() =>
  import('./views/pages/application/notification')
);
const Photospage = React.lazy(() =>
  import('./views/pages/application/SezzMe/Gallery')
);
const SearchView = React.lazy(() => import('./views/pages/application/search'));
const TrendingSezzYouPage = React.lazy(() =>
  import('./views/pages/application/trending')
);

// SEZZ ME
const SezzMeBoard = React.lazy(() =>
  import('./views/pages/application/SezzMe/board')
);
const SezzMeInterests = React.lazy(() =>
  import('./views/pages/application/SezzMe/interests')
);
const SezzMeTopics = React.lazy(() =>
  import('./views/pages/application/SezzMe/topics')
);
const SezzMePoll = React.lazy(() =>
  import('./views/pages/application/SezzMe/Poll')
);
const SezzMeGroup = React.lazy(() =>
  import('./views/pages/application/SezzMe/groups')
);

const SezzMeConversation = React.lazy(() =>
  import('./views/pages/application/SezzMe/favouriteBroadcastConversation')
);
const SezzMeSuggested = React.lazy(() =>
  import('./views/pages/application/SezzMe/suggeted')
);
// ADV
const AdvCampaign = React.lazy(() =>
  import('./views/pages/advertising/advCampaign')
);
const AdvCampaignCreate = React.lazy(() =>
  import('./views/pages/advertising/advCampaignCreate')
);
const AdvCampaignSummary = React.lazy(() =>
  import('./views/pages/advertising/advCampaignSummary')
);
const AdvCampaignView = React.lazy(() =>
  import('./views/pages/advertising/advCampaignView')
);
const AdvBasics = React.lazy(() =>
  import('./views/pages/advertising/advBasics')
);
const AdvPolicy = React.lazy(() =>
  import('./views/pages/advertising/advPolicy')
);

//ADV AUTH
const AdvMyAdvertiseView = React.lazy(() =>
  import('./views/pages/auth/advMyAdvertise')
);
const AdvRegisterView = React.lazy(() =>
  import('./views/pages/auth/advRegister')
);
const AdvLoginView = React.lazy(() => import('./views/pages/auth/advLogin'));
const AdvResetPasswordView = React.lazy(() =>
  import('./views/pages/auth/advResetPassword')
);
const AdvForgotPasswordView = React.lazy(() =>
  import('./views/pages/auth/advForgotPassword')
);
const ErrorView = React.lazy(() => import('./views/pages/application/error'));
const IssueRedirect = React.lazy(() =>
  import('./views/pages/application/Issue')
);
const BackendDown = React.lazy(() =>
  import('./views/pages/application/backendDown')
);
const AdvUserProfileView = React.lazy(() =>
  import('./views/pages/advertising/advUserProfile')
);
const AdvPasswordView = React.lazy(() =>
  import('./views/pages/advertising/advPassword')
);
const AdvPaymentSettingsView = React.lazy(() =>
  import('./views/pages/advertising/advPaymentSetting')
);
const AdvOutstandingDebtView = React.lazy(() =>
  import('./views/pages/advertising/advOutstandingDebt')
);
const AdvInvoiceView = React.lazy(() =>
  import('./views/pages/advertising/advInvoice')
);

// const ComingSoonHTML = () => {
//   return (
//     <iframe
//       src={process.env.PUBLIC_URL + '/comingSoon/coming-soon.html'}
//       title="Coming Soon"
//       style={{ width: '100%', height: '100vh', border: 'none' }}
//     />
//   );
// };
const userRoute = Config?.userRoutePath;
// USER ROUTES
export const userRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: '/', element: <Navigate to={userRoute} /> },
      // { path: "/sign-in", element: <LoginView /> },
      { path: userRoute, element: <Home /> },
      // { path: `${userRoute}welcome`, element: <WelcomeView /> },
      { path: `${userRoute}user-profile`, element: <UserProfile /> },
      { path: `${userRoute}password`, element: <Password /> },
      {
        path: `${userRoute}forgot-password/:token`,
        element: <ForgotPasswordView />
      },
      { path: `${userRoute}reset-password`, element: <ResetPassword /> },
      {
        path: `${userRoute}reset-password/result`,
        element: <ResetPassConfirmationView />
      },
      { path: `${userRoute}my-interests`, element: <Interests /> },
      {
        path: `${userRoute}email-notification`,
        element: <EmailNotification />
      },
      {
        path: `${userRoute}platform-notification`,
        element: <SiteNotification />
      },
      { path: `${userRoute}other-settings`, element: <OtherSettings /> },
      // { path: "/linked-to", element: <LinkedTo /> },
      { path: `${userRoute}blocked-user`, element: <UserBlocked /> },
      {
        path: `${userRoute}verified-status`,
        element: <VerifiedStatus />
      },
      { path: `${userRoute}change-name`, element: <ChangeName /> },
      { path: `${userRoute}topics/:id`, element: <Home /> },
      { path: `${userRoute}topic/:id`, element: <Home /> },
      { path: `${userRoute}poll/:id`, element: <Home /> },
      { path: `${userRoute}quicklink`, element: <Home /> },
      { path: `${userRoute}trending`, element: <Home /> },
      {
        path: `${userRoute}trending-sezzyou`,
        element: <TrendingSezzYouPage />
      },
      { path: `${userRoute}openboard`, element: <SezzMeBoard /> },
      {
        path: `${userRoute}interests`,
        element: <SezzMeInterests />
      },
      { path: `${userRoute}topics`, element: <SezzMeTopics /> },
      { path: `${userRoute}polls`, element: <SezzMePoll /> },
      {
        path: `${userRoute}organizations`,
        element: <SezzMeGroup />
      },
      {
        path: `${userRoute}favorites`,
        element: <SezzMeConversation />
      },
      {
        path: `${userRoute}broadcasts`,
        element: <SezzMeConversation />
      },
      {
        path: `${userRoute}suggested`,
        element: <SezzMeSuggested />
      },
      { path: `${userRoute}listen`, element: <Listenerspage /> },
      { path: `${userRoute}gallery`, element: <Photospage /> },
      {
        path: `${userRoute}agreement`,
        element: <UserAggreementPage />
      },
      {
        path: `${userRoute}privacy-policy`,
        element: <PrivacyPolicyPage />
      },
      { path: `${userRoute}app`, element: <SezzYouAppPage /> },
      {
        path: `${userRoute}announcements`,
        element: <AnnouncementsPage />
      },
      { path: `${userRoute}u/:username`, element: <SezzMeBoard /> },
      {
        path: `${userRoute}u/:username/interests`,
        element: <SezzMeInterests />
      },
      {
        path: `${userRoute}u/:username/topics`,
        element: <SezzMeTopics />
      },
      {
        path: `${userRoute}u/:username/polls`,
        element: <SezzMePoll />
      },
      {
        path: `${userRoute}u/:username/organizations`,
        element: <SezzMeGroup />
      },
      {
        path: `${userRoute}u/:username/favorites`,
        element: <SezzMeConversation />
      },
      {
        path: `${userRoute}u/:username/broadcasts`,
        element: <SezzMeConversation />
      },
      {
        path: `${userRoute}u/:username/listen`,
        element: <Listenerspage />
      },
      {
        path: `${userRoute}u/:username/gallery`,
        element: <Photospage />
      },
      { path: `${userRoute}notification`, element: <Notification /> },
      { path: `${userRoute}search`, element: <SearchView /> },
      {
        path: `${userRoute}server-down`,
        element: <BackendDown />
      },
      {
        path: `${userRoute}404`,
        element: <ErrorView />
      },
      {
        path: '*',
        element: <IssueRedirect />
      }
    ]
  }
];

// INFO ROUTES
export const infoRoutes = {
  path: '/',
  element: <InfoLayout />,
  children: [
    {
      path: `${userRoute}info`,
      element: <Navigate to={`${userRoute}/info/platform`} />
    },
    { path: `${userRoute}info/platform`, element: <PlatformPage /> },
    { path: `${userRoute}info/features`, element: <SitePage /> },
    { path: `${userRoute}info/promise`, element: <PromisePage /> },
    { path: `${userRoute}help`, element: <HelpPage /> },
    {
      path: `${userRoute}help/getting-started`,
      element: <GettingStartedPage />
    },
    { path: `${userRoute}help/home-info`, element: <HomeInfoPage /> },
    { path: `${userRoute}help/trending`, element: <TrendingInfoPage /> },
    {
      path: `${userRoute}help/platform-function`,
      element: <PlatformFuncPage />
    },
    { path: `${userRoute}help/your-section`, element: <YourSectionPage /> },
    { path: `${userRoute}help/your-account`, element: <YourAccountPage /> },
    { path: `${userRoute}help/verified-user`, element: <VerifiedUserPage /> },
    { path: `${userRoute}help/platform`, element: <PlateformNotiPage /> },
    {
      path: `${userRoute}help/contact-support`,
      element: <ContactSupportPage />
    },
    { path: `${userRoute}announcements`, element: <AnnouncementsPage /> },
    { path: `${userRoute}agreement`, element: <UserAggreementPage /> },
    { path: `${userRoute}privacy-policy`, element: <PrivacyPolicyPage /> }
  ]
};

// ADV AUTH ROUTES
export const advPublicRoutes = {
  path: '/',
  element: <AdvAuthLayout />,
  children: [
    { path: `${userRoute}adv`, element: <AdvMyAdvertiseView /> },
    { path: `${userRoute}adv/basics`, element: <AdvBasics /> },
    { path: `${userRoute}adv/policy`, element: <AdvPolicy /> },
    { path: '*', element: <Navigate to={`${userRoute}adv`} /> }
  ]
};
// ADV ALL ACCESS ROUTES
export const advAllAccessRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: `${userRoute}adv/reset-password`,
      element: <AdvResetPasswordView />
    },
    {
      path: `${userRoute}adv/outstanding-debt`,
      element: <AdvOutstandingDebtView />
    },
    {
      path: `${userRoute}reset-password/result`,
      element: <ResetPassConfirmationView />
    },
    {
      path: `${userRoute}adv/forgot-password/:token`,
      element: <AdvForgotPasswordView />
    },
    {
      path: '*',
      element: <IssueRedirect />
    }
  ]
};
// PUBLIC NEWS ROUTE
export const publicNewsRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    { path: `${userRoute}`, element: <Home /> },
    { path: `${userRoute}topics/:id`, element: <Home /> },
    {
      path: `${userRoute}trending-sezzyou`,
      element: <TrendingSezzYouPage />
    },
    { path: `${userRoute}search`, element: <SearchView /> },
    { path: `${userRoute}sign-in/:type`, element: <LoginView /> },
    { path: `${userRoute}trending`, element: <Home /> },
    { path: `${userRoute}poll/:id`, element: <Home /> },
    { path: `${userRoute}topic/:id`, element: <Home /> },
    ,
    { path: `${userRoute}u/:username`, element: <SezzMeBoard /> },
    {
      path: `${userRoute}u/:username/interests`,
      element: <SezzMeInterests />
    },
    {
      path: `${userRoute}u/:username/topics`,
      element: <SezzMeTopics />
    },
    {
      path: `${userRoute}u/:username/polls`,
      element: <SezzMePoll />
    },
    {
      path: `${userRoute}u/:username/organizations`,
      element: <SezzMeGroup />
    },
    {
      path: `${userRoute}u/:username/favorites`,
      element: <SezzMeConversation />
    },
    {
      path: `${userRoute}u/:username/broadcasts`,
      element: <SezzMeConversation />
    },
    {
      path: `${userRoute}u/:username/listen`,
      element: <Listenerspage />
    },
    {
      path: `${userRoute}u/:username/gallery`,
      element: <Photospage />
    }
  ]
};
// PUBLIC ROUTES
export const publicRoute = [
  publicNewsRoutes,
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: `${userRoute}welcome`, element: <WelcomeView /> },
      // { path: `${userRoute}welcome`, element: <WelcomeView /> },
      { path: `${userRoute}sign-in`, element: <LoginView /> },
      { path: `${userRoute}sign-in/:type`, element: <LoginView /> },
      {
        path: `${userRoute}signup`,
        element: <IndividualRegisterView />
      },
      {
        path: `${userRoute}signup/:type`,
        element: <IndividualRegisterView />
      },
      // { path: "/signup/org", element: <GroupRegisterView /> },
      {
        path: `${userRoute}confirmation`,
        element: <ConfirmationView />
      },
      {
        path: `${userRoute}confirmation/:type`,
        element: <ConfirmationView />
      },
      {
        path: `${userRoute}email-confirm/:token`,
        element: <EmailConfirmView />
      },
      {
        path: `${userRoute}forgot-password/:token`,
        element: <ForgotPasswordView />
      },
      { path: `${userRoute}reset-password`, element: <ResetPassword /> },
      {
        path: `${userRoute}reset-password/result`,
        element: <ResetPassConfirmationView />
      },
      { path: `${userRoute}terms-conditions`, element: <TermsView /> },
      { path: `${userRoute}adv/signup`, element: <AdvRegisterView /> },
      {
        path: `${userRoute}adv/confirmation`,
        element: <ConfirmationView />
      },
      {
        path: `${userRoute}adv/email-confirm/:token`,
        element: <EmailConfirmView />
      },
      { path: `${userRoute}adv/signin`, element: <AdvLoginView /> },
      {
        path: `${userRoute}adv/reset-password`,
        element: <AdvResetPasswordView />
      },
      {
        path: `${userRoute}adv/forgot-password/:token`,
        element: <AdvForgotPasswordView />
      },
      {
        path: `${userRoute}server-down`,
        element: <BackendDown />
      },
      {
        path: `${userRoute}404`,
        element: <ErrorView />
      },
      {
        path: '*',
        element: <IssueRedirect />
      }
    ]
  },
  infoRoutes,
  advPublicRoutes,
  advAllAccessRoutes
];

// ADVERTISE ROUTES
export const advertiseRoutes = [
  {
    path: '/',
    element: <AdvLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={`${userRoute}adv/campaign`} />
      },
      { path: `${userRoute}adv/campaign`, element: <AdvCampaign /> },
      {
        path: `${userRoute}adv/campaign/create`,
        element: <AdvCampaignCreate />
      },
      {
        path: `${userRoute}adv/campaign/summary/:advId`,
        element: <AdvCampaignSummary />
      },
      {
        path: `${userRoute}adv/campaign/view/:advId`,
        element: <AdvCampaignView />
      },
      { path: `${userRoute}adv/basics`, element: <AdvBasics /> },
      { path: `${userRoute}adv/policy`, element: <AdvPolicy /> },
      {
        path: `${userRoute}adv/profile`,
        element: <AdvUserProfileView />
      },
      { path: `${userRoute}adv/password`, element: <AdvPasswordView /> },
      {
        path: `${userRoute}adv/payment-settings`,
        element: <AdvPaymentSettingsView />
      },
      {
        path: `${userRoute}adv/invoice/:id`,
        element: <AdvInvoiceView />
      },
      {
        path: `${userRoute}adv/notification`,
        element: <Notification />
      },
      {
        path: `${userRoute}server-down`,
        element: <BackendDown />
      },
      {
        path: `${userRoute}404`,
        element: <ErrorView />
      },
      {
        path: '*',
        element: <IssueRedirect />
      }
    ]
  },
  infoRoutes,
  advAllAccessRoutes
];

// export const publicPrivateRoute = [infoRoutes, publicData];
