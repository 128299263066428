export const AUTH_USER = "AUTH_USER";
export const USER_DETAILS = "USER_DETAILS";
export const SELECT_INFO_MENU = "SELECT_INFO_MENU";
export const USER_PROFULE_DETAILS = "USER_PROFULE_DETAILS";
export const SEARCH = "SEARCH";
export const USER_MEDIA_DETAILS = "USER_MEDIA_DETAILS";
export const SPONSORED_ADVERTISEMENT = "SPONSORED_ADVERTISEMENT";
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const TRENDING_LIST = "TRENDING_LIST";
export const QUICK_LINK_LIST = "QUICK_LINK_LIST";
export const VIEW_TOPIC_POLL = "VIEW_TOPIC_POLL";
export const INTEREST_LIST = "INTEREST_LIST";
export const SUGGESTED_LISTEN = "SUGGESTED_LISTEN";
export const NEWS_LIST = "NEWS_LIST";
export const NEWS_LIST_DATA = "NEWS_LIST_DATA";
