import React, { useEffect, useState } from 'react';
import {
  Menu,
  Box,
  Grid,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import noImage from 'src/assets/images/icons/smallimgnotfound.svg';
import { ReactComponent as PlayIcon } from 'src/assets/images/icons/play-icon.svg';

import moment from 'moment';
import { Config } from 'src/helpers/context';

const NotificationList = ({
  localesData,
  anchorEl,
  handleClose,
  navigate,
  user_data,
}) => {
  const userRoute = Config?.userRoutePath;
  const [loader, setLoader] = useState(false);
  const [notiData, setNotiData] = useState([]);

  // GET NOTIFICATION LIST
  const getNotification = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.getNotification);
      if (status === 200) {
        setNotiData(data?.data?.slice(0, 10));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className='menu-wrap-notification'
      id='notification-list'
    >
      <Box className='main-notification-container'>
        <Box className='notification-content-block'>
          <Box className='notification-head'>
            <Typography variant='h6' className='notification-heading'>
              {localesData?.home_page?.Notification}
            </Typography>
            <Typography
              variant='h6'
              className='see-all-link pointer'
              onClick={() => {
                navigate(
                  user_data?.subclass_id === 3
                    ? `${userRoute}adv/notification`
                    : `${userRoute}notification`
                );
                handleClose();
              }}
            >
              {localesData?.home_page?.see_all}
            </Typography>
          </Box>
          <Divider className='divider' />
          {notiData?.map((obj, i) => {
            return (
              <Box key={i}>
                <Grid
                  className={`${
                    obj?.is_read === 'true'
                      ? 'unread-notification'
                      : 'read-notification'
                  }`}
                  container
                  // onClick={() =>
                  //   obj?.is_read === "false"
                  //     ? handleUpdateNotification(obj?._id)
                  //     : null
                  // }
                >
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    {obj?.media_type !== 'video' ? (
                      <LazyLoadImage
                        src={obj?.img_entity_id || noImage}
                        alt=''
                        className={`notification-profile-home ${
                          obj?.img_entity_id ? 'radius-50' : ''
                        }`}
                      />
                    ) : (
                      <Box className='video-wrapper'>
                        <video
                          src={obj?.img_entity_id || noImage}
                          alt=''
                          className={`notification-profile-home ${
                            obj?.img_entity_id ? 'radius-50' : ''
                          }`}
                        />
                        <PlayIcon className='video-play-icon' />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <Box>
                      <Typography variant='h5' className='notification-name'>
                        {obj?.title}
                      </Typography>
                      <Typography
                        variant='h6'
                        className='notification-data'
                        dangerouslySetInnerHTML={{ __html: obj?.body }}
                      />
                    </Box>
                    <Box className='notification-time-detail'>
                      <Typography
                        variant='h6'
                        className=' notification-date-wrap'
                      >
                        {moment
                          .utc(obj?.createdAt)
                          .local()
                          .format('ddd DD MMM YYYY')}
                      </Typography>
                      <Typography
                        variant='h6'
                        className=' notification-date-wrap'
                      >
                        {moment.utc(obj?.createdAt).local().format('hh:mm A')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {i !== notiData?.length - 1 ? (
                  <Divider className='divider' />
                ) : null}
              </Box>
            );
          })}
          <Box m='18px' textAlign='center'>
            {loader && <CircularProgress />}
          </Box>
          {notiData?.length !== 0 && (
            <Box>
              <Divider className='divider' />
              <Box m='18px' textAlign='center'>
                <Typography
                  variant='h6'
                  className='see-all-link pointer'
                  onClick={() => {
                    navigate(
                      user_data?.subclass_id === 3
                        ? `${userRoute}adv/notification`
                        : `${userRoute}notification`
                    );
                    handleClose();
                  }}
                >
                  {localesData?.home_page?.see_all}
                </Typography>
              </Box>
            </Box>
          )}
          {!loader && notiData?.length === 0 && (
            <Box className='not-found'>
              <Typography variant='h5'>
                {localesData.not_found.no_notification}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Menu>
  );
};
export default NotificationList;
