import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    Typography,
    InputAdornment,
    Button
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { identifiers } from 'src/helpers/constants/identifier';

import Logo from 'src/components/Logo';
import { IntlContext } from 'src/App';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search.svg';
// import { ReactComponent as HomeIcon } from 'src/assets/images/icons/homeicon.svg';
import { ReactComponent as HotRed } from 'src/assets/images/icons/hot-red.svg';

import { useDispatch } from 'react-redux';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { socket } from 'src/helpers/context/socket';
import { notificationCount } from 'src/helpers/store/Actions/user';

import { CustomTextField } from 'src/views/component/UI/textfield';
import { Config, fetchFromStorage } from 'src/helpers/context';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ffffff',
    },
}));

const PublicTopBar = ({ auth, user_data, verify_count, className, ...rest }) => {
    const userRoute = Config?.userRoutePath;
    const intlContext = useContext(IntlContext);
    const localesData = intlContext?.messages;
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let searchValue = new URLSearchParams(window.location.search).get('q');
    let searchText = searchValue
        ? searchValue
        : window.location.pathname.includes(`${userRoute}search`)
            ? searchValue
            : '';
    const [openSearch, setOpenSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [searchList, setSearchList] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // SEARCH LIST
    const getSearchData = async (value) => {
        try {
            const { status, data } = await axiosInstance.get(
                URLS.searchListPublic + `?search=${value?.slice(0, 50)}`
            );
            if (status === 200) {
                setSearchList(data?.data);
            }
        } catch (error) {
            setApiMessage('error', error?.response?.data?.message);
        }
    };

    useEffect(() => {
        if (inputValue || search) getSearchData(inputValue);
        // eslint-disable-next-line
    }, [inputValue]);

    const searchField = () => {
        return (
            <>
                <Autocomplete
                    id='free-solo-demo'
                    freeSolo
                    size='small'
                    options={searchList?.map((item) => {
                        return (
                            item?.title ||
                            item?.user_name ||
                            item?.first_name ||
                            item?.last_name
                        );
                    })}
                    value={search || ''}
                    inputValue={inputValue || ''}
                    onChange={(e, newValue) => {
                        setSearch(newValue);
                        navigate(
                            `${userRoute}search?q=${newValue ? newValue?.slice(0, 50) : ''}`
                        );
                    }}
                    onInputChange={(e, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <CustomTextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            className='custom-textfield header-search-field'
                            placeholder={localesData?.home_page?.search_here}
                            size='small'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        onClick={() => setOpenSearch(false)}
                                    >
                                        {openSearch ? <Close /> : <SearchIcon />}
                                        {/* <SearchIcon /> */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                />
            </>
        );
    };


    useEffect(() => {
        setSearch(searchText);
        setInputValue(searchText);
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        searchText === '' && setSearchList([]);
    }, [searchText]);

    useEffect(() => {
        socket.on('CountSocket', (data) => {
            dispatch(notificationCount(data?.notificationCount));
        });

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <AppBar
                className={`${clsx(classes.root, className)} app-bar`}
                elevation={0}
                {...rest}
            >
                <Toolbar>
                    {/* {!auth && (
                        <NavLink to={`${userRoute}`} className='logo-anchor'>
                            <Logo />
                        </NavLink>
                    )} */}
                    {/* <Box flexGrow={1} /> */}


                    <Hidden smDown>
                        <Box className='left-side-menu-block'>
                            <NavLink to={fetchFromStorage(identifiers?.isBot) ? `${userRoute}welcome` : `${userRoute}`} className='logo-anchor'>
                                <Logo />
                            </NavLink>
                            {/* {user_data?.subclass_id !== 3 && ( */}
                            <>
                                {/* HOME */}
                                {/* HOT */}
                                <Box className='appbar-menu-block appbar-trending'>
                                    <Box
                                        mr='10px'
                                        className={`appbar-user-block pointer appbar-home menu-hover ${location.pathname ===
                                            `${userRoute}trending-sezzyou` && 'menu-active'
                                            }`}
                                        onClick={() => navigate(`${userRoute}trending-sezzyou`)}
                                    >
                                        <IconButton className='app-bar-icon-btn app-bar-btn-home'>
                                            <HotRed />
                                        </IconButton>
                                        <Typography variant='h5' className='hide-1024'>
                                            {localesData?.home_page?.trending}
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                            {/* )} */}
                        </Box>
                        {/* SEARCH */}
                        {/* {user_data?.subclass_id !== 3 && ( */}
                        <Box flexGrow={1.5}>{searchField()}</Box>
                        {/* )} */}
                        {/* USER PROFILE */}
                        <Box className='appbar-menu-block'>
                            <Box
                                ml='10px'
                                className='appbar-user-block pointer menu-hover'
                                onClick={() => {
                                    // user_data?.subclass_id === 3
                                    //     ? navigate(`${userRoute}adv/profile`)
                                    //     : navigate(`${userRoute}openboard`);
                                }}
                            >
                            </Box>
                        </Box>
                        <Button
                            variant='outlined'
                            className='btn-blue individual-btn'
                            onClick={() => navigate(`${userRoute}sign-in`)}
                        >
                            {localesData?.welcome?.login}
                        </Button>
                    </Hidden>


                    {!openSearch && (
                        <Hidden mdUp>
                            <Box className='left-side-menu-block'>
                                {!openSearch && (
                                    <>
                                    <NavLink
                                        to={fetchFromStorage(identifiers?.isBot) ? `${userRoute}welcome` : `${userRoute}`}
                                        className='logo-anchor'
                                    >
                                        <Logo />
                                    </NavLink>
                                     <Box
                                        mr='10px'
                                        className={`appbar-user-block pointer sezz-you-icon appbar-home menu-hover ${location.pathname ===
                                            `${userRoute}trending-sezzyou` && 'menu-active'
                                            }`}
                                        onClick={() => navigate(`${userRoute}trending-sezzyou`)}
                                    >
                                        <IconButton className='app-bar-icon-btn app-bar-btn-home'>
                                            <HotRed />
                                        </IconButton>
                                    </Box>
                                    </>
                                )}
                            </Box>
                            <IconButton
                                className='app-bar-icon-btn'
                                onClick={() => setOpenSearch(!openSearch)}
                            >
                                <SearchIcon />
                            </IconButton>

                            <Button
                                variant='outlined'
                                className='btn-blue individual-btn'
                                onClick={() => navigate(`${userRoute}sign-in`)}
                            >
                                {localesData?.welcome?.login}
                            </Button>



                        </Hidden>
                    )}
                </Toolbar>
                <Hidden mdUp>
                    {openSearch && (
                        <Box className='search-mobile-view'>{searchField()}</Box>
                    )}
                </Hidden>
            </AppBar>
        </>
    );
};

PublicTopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default PublicTopBar;
