import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IntlContext } from "src/App";

const PlatformFunc = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {
                localesData?.info_content?.help_center?.platform_function
                  ?.platform_function
              }
            </Typography>
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.platform_function
                    ?.desc1,
              }}
            />

            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head1,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc1_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc1_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc1_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc1_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head2,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc2_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc2_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc2_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc2_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head3,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc3_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc3_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc3_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc3_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc3_5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head4,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc4_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc4_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc4_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc4_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc4_5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head5,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc5_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc5_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc5_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc5_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head6,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc6_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc6_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc6_3,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head7,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc7_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc7_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc7_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc7_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc7_5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head8,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_5,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc8_6,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head9,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc9_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc9_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc9_3,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head10,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc10_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc10_2,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.head11,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc11_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.platform_function
                        ?.desc11_2,
                  }}
                />
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.head11_1,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.desc11_1_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.head11_2,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.desc11_2_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.head11_3,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.desc11_3_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.head11_4,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.desc11_4_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.head11_5,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center
                            ?.platform_function?.desc11_5_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlatformFunc;
