import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";

import { IntlContext } from "src/App";

import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { setApiMessage } from "src/helpers/commonFunctions";

import "src/views/component/application/Info/info.scss";
import moment from "moment";

const Announcements = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [announcement, setAnnouncement] = useState([]);
  // GET ANNOUNCEMENTS
  const getAnnouncement = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.announcement);
      if (status === 200) {
        setAnnouncement(data?.data);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAnnouncement();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="user_agreement announcement-container des_text">
            <Typography variant="h5" className="font-22 agreement_head">
              {localesData?.register?.about_sezz_announcements}
            </Typography>

            <Box className="agreement_text">
              {announcement?.map((item, i) => {
                return (
                  <Box key={i}>
                    <Box
                      className="mb-17"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" className="link_text bold_text">
                        {item?.title}
                      </Typography>
                      <Typography variant="h5">
                        {item?.pub_date
                          ? moment
                              .utc(item?.pub_date)
                              .local()
                              .format("DD/MM/YYYY hh:mm")
                          : "N/A"}
                      </Typography>
                    </Box>

                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{ __html: item?.content }}
                    />
                    {/* {item?.content}
                    </Typography> */}
                    <Divider className="divider mb-17" />
                  </Box>
                );
              })}
              {announcement?.length === 0 && (
                <Box textAlign="center">
                  <Typography variant="h5">
                    {localesData?.not_found?.no_announcement}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Announcements;
