import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "../info.scss";
import Terms from "src/views/component/auth/Terms";

const UserAggreement = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="des_text user_agreement">
            <Typography variant="h5" className="agreement_head font-22">
              {localesData?.agreement?.title}
            </Typography>
            <Box className="agreement_text">
              <Terms />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAggreement;
