import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IntlContext } from "src/App";

const YourSection = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {
                localesData?.info_content?.help_center?.your_section
                  ?.your_section
              }
            </Typography>
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_section?.desc1,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_section?.desc2,
              }}
            />
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head1,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc1_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head2,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc2_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc2_2,
                  }}
                />

                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc2_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc2_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head3,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc3_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head4,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc4_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head5,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc5_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head6,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc6_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head7,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc7_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head8,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc8_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head9,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc9_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head10,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc10_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head11,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc11_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head12,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc12_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.head13,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_section
                        ?.desc13_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default YourSection;
