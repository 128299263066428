import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ScrollToTop from './ScrollTop';
import { Config } from 'src/helpers/context';

const userRoute = Config?.userRoutePath;

const Page = forwardRef(
  ({ children, title = '', description = '', ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        {window.location.pathname.includes(`${userRoute}info`) ||
        window.location.pathname.includes(`${userRoute}help`) ||
        window.location.pathname.includes(`${userRoute}adv`) ||
        window.location.pathname.includes(`${userRoute}announcements`) ||
        window.location.pathname.includes(`${userRoute}app`) ||
        window.location.pathname.includes(`${userRoute}privacy-policy`) ||
        window.location.pathname.includes(`${userRoute}agreement`) ? null : (
          <ScrollToTop />
        )}
        <Helmet
          onChangeClientState={newState => {
            // Add description
            const metaDescription = document.querySelector(
              'meta[name="description"]'
            );
            if (metaDescription) {
              metaDescription.setAttribute('content', description || '');
            }
            // Add og:title
            const ogTitleTag = document.querySelector(
              'meta[property="og:title"]'
            );
            if (ogTitleTag) {
              ogTitleTag.setAttribute('content', '');
            }
            // Keywords
            const keywordsTag = document.querySelector('meta[name="keywords"]');
            if (keywordsTag) {
              keywordsTag.setAttribute('content', '');
            }
            // Add og:image
            const ogImageTag = document.querySelector(
              'meta[property="og:image"]'
            );
            if (ogImageTag) {
              ogImageTag.setAttribute('content', '');
            }
          }}
        >
          <title>{title}</title>
          {/* <meta name="description" content={description} /> */}
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

export default Page;
