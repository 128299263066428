import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
// import { Back } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import "./ui.scss";

const DialogBox = ({ handleClose, open, title, content, closeIcon, backIcon }) => {
  return (
    <Dialog
      className="custom-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        <DialogTitle>
          <Box className="custom-dialog-title">
            {backIcon && (
              <IconButton className="dialog-back" onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h3" className={backIcon ? "dialog-title ml-0" : "dialog-title"}>
              {title}
            </Typography>
            {closeIcon !== false && (
              <IconButton className="dialog-close" onClick={handleClose}>
                <Close />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      </Box>
      {content}
    </Dialog>
  );
};

export default DialogBox;
