import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, makeStyles, Hidden } from '@material-ui/core';
import Logo from 'src/components/Logo';
import LogoText from '../../assets/images/text-logo.svg';
import { Config } from 'src/helpers/context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
  },
  title: {
    marginRight: 'auto',
  },
  button: {
    color: '#fff',
    fontSize: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '0px 8px',
    textTransform: 'none',
    marginRight: '25px',
    '&:hover': { backgroundColor: 'transparent' },
    '&:last-child': {
      marginRight: 0,
    },
  },
  avatar: { width: 36, height: 36, cursor: 'pointer' },
  active: {
    color: '#438AC1',
    fontSize: '21px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '0px 8px',
    textTransform: 'none',
    marginRight: '25px',
    '&:hover': { backgroundColor: 'transparent' },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const TopBar = ({
  auth,
  user_statics,
  verify_count,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const userRoute = Config?.userRoutePath;
  const classes = useStyles();

  return (
    <AppBar
      className={`${clsx(classes.root, className)} app-bar`}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <NavLink to={userRoute} className='logo-anchor'>
          <Hidden xsDown>
            <Logo />
          </Hidden>
          <Hidden smUp>
            <LazyLoadImage alt='Logo' src={LogoText} />
          </Hidden>
        </NavLink>
        <Box />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
