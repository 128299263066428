import {
  USER_DETAILS,
  USER_PROFULE_DETAILS,
  USER_MEDIA_DETAILS,
  NOTIFICATION_COUNT,
  SUGGESTED_LISTEN,
} from "../constants";

export const userDetails = (data) => {
  return {
    type: USER_DETAILS,
    data: data,
  };
};

export const userProfileDetails = (data) => {
  return {
    type: USER_PROFULE_DETAILS,
    data: data,
  };
};

export const mediaDetails = (data) => {
  return {
    type: USER_MEDIA_DETAILS,
    data: data,
  };
};

export const notificationCount = (data) => {
  return {
    type: NOTIFICATION_COUNT,
    data: data,
  };
};

export const userSuggestedListen = (data) => {
  return {
    type: SUGGESTED_LISTEN,
    data: data,
  };
};
