import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "src/views/component/application/Info/info.scss";

const SezzYouApp = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="user_agreement des_text">
            <Typography variant="h5" className="font-22 agreement_head">
              {localesData?.register?.sezz_you_app}
            </Typography>
            <Typography
              variant="h5"
              className="agreement_text"
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.sezz_you_app?.desc1,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SezzYouApp;
