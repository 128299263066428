import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  withStyles,
  Hidden,
  Collapse,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { IntlContext } from 'src/App';
import { Config } from 'src/helpers/context';

const drawerWidth = 324;

// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    left: 'auto',
    marginTop: '80px',
    paddingBottom: '100px',
    width: drawerWidth,
    background: 'transparent',
    border: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuList: {
    padding: 0,
    background: '#E9EDF1',
  },
}));

// SIDE DRAWER MENU
const MuiListItem = withStyles({
  root: {
    width: 'auto',
    borderRadius: '8px',
    // margin: "3px 18px 0px 18px",
    padding: '7px 18px',
    height: '45px',
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: 0,
        color: '#191919',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'Inter, sans-serif',
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: '30px',
    },
    '&$selected': {
      background: 'linear-gradient(to bottom, #4582C3, #45C1C3)',
      color: '#ffffff',
      // marginRight: "18px",
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          color: '#ffffff',
        },
      },
    },
  },
  selected: {},
})(ListItem);

const LeftSideBar = ({ menusItems }) => {
  const userRoute = Config?.userRoutePath;
  const classes = useStyles();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState({});
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  useEffect(() => {
    if (location.pathname.includes(`${userRoute}info/`)) {
      setOpenMenu({
        ...openMenu,
        [`${localesData.register.about_sezz_you}`]: true,
      });
    }
    if (location.pathname.includes(`${userRoute}help`)) {
      setOpenMenu({
        ...openMenu,
        [`${localesData?.register?.help_centre}`]: true,
      });
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleClick = (item) => {
    setOpenMenu((prevState) => ({
      ...openMenu,
      [item?.title]: !prevState[item?.title],
    }));
  };

  const handleNestedMenus = (children) => {
    return children?.map((subOption) => {
      if (!subOption?.submenu) {
        return (
          <div key={subOption?.title}>
            <MuiListItem
              button
              component={Link}
              to={subOption?.href}
              selected={subOption?.href === location.pathname}
            >
              <ListItemText>{subOption?.title}</ListItemText>
              {/* <ListItemText primary={subOption?.name} /> */}
            </MuiListItem>
          </div>
        );
      }
      return (
        <div key={subOption?.title}>
          <MuiListItem
            button
            component={Link}
            to={subOption?.href}
            selected={subOption?.href === location.pathname}
            onClick={() => handleClick(subOption)}
          >
            {subOption?.submenu?.length !== 0 ? (
              openMenu[subOption?.title] ? (
                <ArrowDropUp />
              ) : (
                <ArrowDropDown />
              )
            ) : (
              <Box style={{ marginLeft: '25px' }}></Box>
            )}
            {/* <ListItemText primary={subOption?.name} /> */}
            <ListItemText>{subOption?.title}</ListItemText>
          </MuiListItem>
          <Collapse
            in={openMenu[subOption?.title]}
            timeout='auto'
            unmountOnExit
          >
            <Box className='submenu-option'>
              {handleNestedMenus(subOption?.submenu)}
            </Box>
          </Collapse>
        </div>
      );
    });
  };

  const policy_Menu = [
    {
      href: `${userRoute}agreement`,
      title: localesData?.register?.user_agreement,
    },
    {
      href: `${userRoute}privacy-policy`,
      title: localesData?.register?.privacy_policy,
    },
  ];

  return (
    <Hidden mdDown>
      <Box className='left-menu-drawer'>
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={classes.drawer}
          classes={{
            paper: classes.drawerOpen,
          }}
        >
          {/* INFO */}
          <Box className='info-block'>
            <Typography variant='h5' className='font-22 info-title'>
              {localesData.info.information}
            </Typography>
          </Box>
          {/* MENUS LIST */}
          <List className={classes.menuList}>
            <Box>{handleNestedMenus(menusItems)}</Box>
          </List>

          {/* POLICIES */}
          <Box className='info-block info-policy-block'>
            <Typography variant='h5' className='font-22 info-title'>
              {localesData.info.policies}
            </Typography>
          </Box>
          {/* POLICIES LIST */}
          <List className={classes.menuList}>
            <Box>
              {policy_Menu?.map((item, i) => (
                <Box key={i}>
                  <MuiListItem
                    button
                    component={Link}
                    to={item?.href}
                    selected={item?.href === location.pathname}
                    disableRipple
                  >
                    <ListItemText>{item?.title}</ListItemText>
                  </MuiListItem>
                </Box>
              ))}
            </Box>
          </List>
        </Drawer>
      </Box>
    </Hidden>
  );
};

export default LeftSideBar;
