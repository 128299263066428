import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { IntlContext } from 'src/App';
import { ToastContainer } from 'react-toastify';
import { identifiers } from 'src/helpers/constants/identifier';

import { Config, fetchFromStorage, saveToStorage } from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from 'src/helpers/store/Actions/user';

import TopBar from '../MainLayout/TopBar';
import AdvLeftMenus from './AdvLeftMenu';
// import CustomInfoBreadcrumbs from "src/views/component/UI/InfoBreadcrumbs";

// import "src/layouts/layout.scss";
import 'src/layouts/AdvLayout/advLayout.scss';
// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const AdvAuthLayout = () => {
  const userRoute = Config?.userRoutePath;
  const dispatch = useDispatch();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.reduxData?.authData);
  const redux_userData = useSelector((state) => state?.reduxData?.userData);
  const user_data = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers.userData);
  const auth = redux_auth ? redux_auth : fetchFromStorage(identifiers.authData);

  const verify_count = useSelector(
    (state) => state?.reduxData?.user_profile_count
  );

  const menusItems = [
    {
      href: `${userRoute}adv`,
      title: localesData.adv.my_adv,
      submenu: [],
    },
    {
      href: `${userRoute}adv/basics`,
      title: localesData.adv.adv_basics,
      submenu: [],
    },
    {
      href: `${userRoute}adv/policy`,
      title: localesData.adv.adv_policy,
      submenu: [],
    },
  ];

  // GET USER PROFILE DATA
  const getUserProfileData = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getUserProfile);
      if (status === 200) {
        saveToStorage(identifiers.userData, data?.data);
        dispatch(userDetails(data?.data));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    auth && getUserProfileData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      <TopBar auth={auth} user_data={user_data} verify_count={verify_count} />
      <Box className='layout-container'>
        <AdvLeftMenus menusItems={menusItems} />
        <Box className='adv-layout-content'>
          {/* <CustomInfoBreadcrumbs navigateList={menusItems} />
          <Divider className="info-divider" /> */}
          {/* <div className="layout-wrapper"> */}
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
          {/* </div> */}
        </Box>
      </Box>
    </div>
  );
};
export default AdvAuthLayout;
