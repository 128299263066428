import { Hidden } from "@material-ui/core";
import React from "react";
import logo from "../assets/images/logo.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";

const Logo = (props) => {
  return (
    <>
      <Hidden xsDown>
        <LazyLoadImage alt="Logo" src={logo} {...props} />
      </Hidden>
      <Hidden smUp>
        <LazyLoadImage alt="Logo" src={logo} {...props} />
      </Hidden>
    </>
  );
};

export default Logo;
