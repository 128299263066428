import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IntlContext } from "src/App";
import DialogBox from "src/views/component/UI/dialogBox";
import CloseAccountContent from "./CloseAccountContent";
const YourAccount = () => {
  const [toggleOpen, setToggleOpen] = useState(false);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {
                localesData?.info_content?.help_center?.your_account
                  ?.your_account
              }
            </Typography>

            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account?.desc1,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account?.desc2,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account?.desc3,
              }}
            />

            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head1,
                  }}
                />
              </AccordionSummary>

              <AccordionDetails>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_1,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc1_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc1_2,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc1_3,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_2,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc2_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc2_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_3,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc3_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc3_2,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc3_3,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc3_4,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_4,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc4_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc4_2,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc4_3,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc4_4,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_5,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc5_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc5_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_6,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc6_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc6_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_7,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc7_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc7_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_8,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc8_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc8_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_9,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc9_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc9_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_10,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc10_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc10_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_11,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc11_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc11_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_12,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc12_1,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion className="mb-17">
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head1_13,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc13_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc13_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.desc13_3,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head2,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head2_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head2_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head2_3,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head3_5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head4,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head4_1,
                  }}
                />
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head4_2,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc1_4,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head4_3,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc4_1_3,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5_4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head5_5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head6,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head6_1,
                  }}
                />
                <Accordion>
                  <AccordionSummary
                    className="accordion_text"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.head6_1_1,
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc6_1_1,
                      }}
                    />
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html:
                          localesData?.info_content?.help_center?.your_account
                            ?.desc6_1_2,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head7,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head7_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head7_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head7_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head7_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head8,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head8_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head8_2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head8_3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head8_4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head9,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head9_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.head9_2,
                  }}
                />
                <Box style={{ textAlign: "center" }} className="mb-17">
                  <Button
                    className="btn-blue"
                    onClick={() => setToggleOpen(!toggleOpen)}
                  >
                    {
                      localesData?.info_content?.help_center?.your_account
                        ?.close?.close_btn
                    }
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <DialogBox
            handleClose={() => setToggleOpen(!toggleOpen)}
            open={toggleOpen}
            title={
              localesData?.info_content?.help_center?.your_account?.close
                ?.account_summary
            }
            content={
              <CloseAccountContent
                setToggleOpen={setToggleOpen}
                toggleOpen={toggleOpen}
                localesData={localesData}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default YourAccount;
