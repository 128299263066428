import React, { useState } from "react";
import { Box, Grid, Typography, Button, Divider } from "@material-ui/core";
import { fetchFromStorage } from "src/helpers/context";
import { identifiers } from "src/helpers/constants/identifier";

import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { setApiMessage } from "src/helpers/commonFunctions";

import { useSelector, useDispatch } from "react-redux";

import PreLoader from "src/components/Loader";
import { userDetails } from "src/helpers/store/Actions/user";

const CloseAccountContent = ({ localesData, setToggleOpen, toggleOpen }) => {
  const dispatch = useDispatch();
  const redux_userData = useSelector((state) => state?.reduxData?.userData);
  const loginUser = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers?.userData);
  const [loader, setLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // CLOSE ACCOUNT
  const handleCloseAccount = async () => {
    setIsDisabled(true);
    setLoader(false);
    try {
      const { status, data } = await axiosInstance.get(URLS.closeAccount);
      if (status === 200) {
        if (data?.status) {
          setApiMessage("success", data?.message);
          let listenRedux = {
            ...redux_userData,
            closed_status: true,
          };
          dispatch(userDetails(listenRedux));
        } else {
          setApiMessage("error", data?.message);
        }
        setIsDisabled(false);
        setToggleOpen(!toggleOpen);
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setIsDisabled(false);
      setToggleOpen(!toggleOpen);
      setLoader(false);
    }
  };
  // CLOSE ACCOUNT CANCEL
  const handleCancelAccount = async () => {
    setIsDisabled(true);
    setLoader(false);
    try {
      const { status, data } = await axiosInstance.get(URLS.cancelAccount);
      if (status === 200) {
        if (data?.status) {
          setApiMessage("success", data?.message);
          let listenRedux = {
            ...redux_userData,
            closed_status: false,
          };
          dispatch(userDetails(listenRedux));
        } else {
          setApiMessage("error", data?.message);
        }
        setIsDisabled(false);
        setToggleOpen(!toggleOpen);
        setLoader(false);
      }
    } catch (error) {
      setApiMessage("error", error?.response?.data?.message);
      setIsDisabled(false);
      setToggleOpen(!toggleOpen);
      setLoader(false);
    }
  };

  return (
    <Box className="main-content-block">
      {loader && <PreLoader />}
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="popup-content">
            {/* USER TYPE */}
            <Grid container className="details-content mb-17">
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Typography
                  variant="h5"
                  className="profile-title font-weight-600"
                >
                  {localesData?.user_account?.user_type}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <Typography variant="h5" className="profile-value space_box">
                  {loginUser?.user_type}
                </Typography>
              </Grid>
            </Grid>
            {/* NAME */}
            <Grid container className="details-content mb-17">
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Typography
                  variant="h5"
                  className="profile-title font-weight-600"
                >
                  {localesData?.user_account?.name}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <Typography variant="h5" className="profile-value space_box">
                  {loginUser?.name}
                </Typography>
              </Grid>
            </Grid>
            {/* USER NAME */}
            <Grid container className="details-content mb-17">
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Typography
                  variant="h5"
                  className="profile-title font-weight-600"
                >
                  {localesData?.user_account?.user_name}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <Typography variant="h5" className="profile-value space_box">
                  {loginUser?.user_name}
                </Typography>
              </Grid>
            </Grid>
            {/* EMAIL */}
            <Grid container className="details-content mb-17">
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Typography
                  variant="h5"
                  className="profile-title font-weight-600"
                >
                  {localesData?.email}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <Typography variant="h5" className="profile-value space_box">
                  {loginUser?.email}
                </Typography>
              </Grid>
            </Grid>
            {/* STATUS */}
            <Grid container className="details-content mb-17">
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <Typography
                  variant="h5"
                  className="profile-title font-weight-600"
                >
                  {localesData?.user_account?.verification_status}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <Typography variant="h5" className="profile-value space_box">
                  {loginUser?.verified}
                </Typography>
              </Grid>
            </Grid>
            <Divider className="divider mb-17" />
            <Typography variant="h5" className="bold_text reg_text">
              {
                localesData?.info_content?.help_center?.your_account?.close
                  ?.please_note
              }
            </Typography>
            <Typography
              variant="h5"
              className="reg_text mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account?.close
                    ?.note1,
              }}
            />
            <Typography
              variant="h5"
              className="reg_text mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account?.close
                    ?.note2,
              }}
            />
            {loginUser && (
              <Box style={{ textAlign: "center" }}>
                <Button
                  className="btn-blue"
                  disabled={isDisabled}
                  onClick={() =>
                    loginUser?.closed_status
                      ? handleCancelAccount()
                      : handleCloseAccount()
                  }
                >
                  {loginUser?.closed_status
                    ? localesData?.info_content?.help_center?.your_account
                        ?.close?.cancel_close
                    : localesData?.info_content?.help_center?.your_account
                        ?.close?.close}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CloseAccountContent;
