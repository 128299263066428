import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from './context';
export const setApiMessage = (type, message) => {
  // return () => {
  var commonProps = {
    position: 'bottom-center',
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    hideProgressBar: true,
    theme: 'colored',
    style: {
      // background: "#438ac1",
      // color: "#ffffff",
      minHeight: 'unset'
    },
    toastId: 'active'
  };
  switch (type) {
    case 'info':
      toast.info(message, commonProps);
      break;
    case 'success':
      toast.success(message, commonProps);
      break;
    case 'warning':
      toast.warning(message, commonProps);
      break;
    case 'error':
      toast.error(message, commonProps);
      break;
    default:
      break;
  }
  // toast.clearWaitingQueue();
  // };
};

// Submit Hidden Form For the Issue/SSR
export const submitForm = postData => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = Config?.issueProxy + 'issue';

  Object.keys(postData).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = postData[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

// Get Error Message Base on Status Code

const getStatusMessage = statusCode => {
  let message = '';
  switch (statusCode) {
    // Client-related status codes
    case 100:
      message = 'Continue';
      break;
    case 101:
      message = 'Switching_Protocols';
      break;
    case 102:
      message = 'Processing';
      break;
    case 200:
      message = 'OK';
      break;
    case 201:
      message = 'Created';
      break;
    case 202:
      message = 'Accepted';
      break;
    case 204:
      message = 'No_Content';
      break;
    case 400:
      message = 'Bad_Request';
      break;
    case 401:
      message = 'Unauthorized';
      break;
    case 403:
      message = 'Forbidden';
      break;
    case 404:
      message = 'Not_Found';
      break;
    case 405:
      message = 'Method_Not_Allowed';
      break;
    case 500:
      message = 'Internal_Server_Error';
      break;
    case 501:
      message = 'Not_Implemented';
      break;
    case 502:
      message = 'Bad_Gateway';
      break;
    case 503:
      message = 'Service_Unavailable';
      break;
    case 504:
      message = 'Gateway_Timeout';
      break;
    // Server-related status codes
    case 505:
      message = 'HTTP_Version_Not_Supported';
      break;
    case 506:
      message = 'Variant_Also_Negotiates';
      break;
    case 507:
      message = 'Insufficient_Storage';
      break;
    case 508:
      message = 'Loop_Detected';
      break;
    case 510:
      message = 'Not_Extended';
      break;
    case 511:
      message = 'Network_Authentication_Required';
      break;
    default:
      message = 'Unknown_Status_Code';
      break;
  }
  return message;
};

export default getStatusMessage;
