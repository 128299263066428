import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles, Box, Hidden, Divider } from '@material-ui/core';
import { IntlContext } from 'src/App';
import { ToastContainer } from 'react-toastify';
import { identifiers } from 'src/helpers/constants/identifier';

import { Config, fetchFromStorage, saveToStorage } from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from 'src/helpers/store/Actions/user';

import TopBar from '../MainLayout/TopBar';
import AdvLeftMenus from './AdvLeftMenu';
// import RightSide from "../MainLayout/RightSide";

import AdvRightSide from './AdvRightSide';
import CustomInfoBreadcrumbs from 'src/views/component/UI/InfoBreadcrumbs';

// import "src/layouts/layout.scss";
import 'src/layouts/AdvLayout/advLayout.scss';

// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const AdvLayout = () => {
  const userRoute = Config?.userRoutePath;
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.reduxData?.authData);
  const redux_userData = useSelector((state) => state?.reduxData?.userData);
  const user_data = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers.userData);
  const auth = redux_auth ? redux_auth : fetchFromStorage(identifiers.authData);
  const verify_count = useSelector(
    (state) => state?.reduxData?.user_profile_count
  );

  const profileMenusItems = [
    {
      href: `${userRoute}adv/profile`,
      title: localesData?.main_layout?.account_profile,
      submenu: [],
    },
    {
      href: `${userRoute}adv/password`,
      title: localesData?.main_layout?.password,
      submenu: [],
    },
    {
      href: `${userRoute}adv/payment-settings`,
      title: localesData.adv.payment_settings,
      submenu: [],
    },
  ];

  const menusItems = [
    {
      href: `${userRoute}adv/campaign`,
      title: localesData.adv.my_adv,
      submenu: [],
    },
    {
      href: `${userRoute}adv/basics`,
      title: localesData.adv.adv_basics,
      submenu: [],
    },
    {
      href: `${userRoute}adv/policy`,
      title: localesData.adv.adv_policy,
      submenu: [],
    },
  ];
  const userOtherMenu = [
    {
      href: `${userRoute}adv/payment-settings`,
      title: localesData.adv.payment_methods,
      submenu: [],
    },
  ];
  const menusItemsList =
    user_data && user_data?.subclass_id === 0
      ? [...menusItems, ...userOtherMenu]
      : menusItems;

  const breadcrumbMenus = [
    {
      href: `${userRoute}adv/profile`,
      title: localesData?.main_layout?.account_profile,
      submenu: [],
    },
    {
      href: `${userRoute}adv/password`,
      title: localesData?.main_layout?.password,
      submenu: [],
    },
    {
      href: `${userRoute}adv/payment-settings`,
      title: localesData.adv.payment_settings,
      submenu: [],
    },
    {
      href: `${userRoute}adv/campaign`,
      title: localesData.adv.my_adv,
      submenu: [],
    },
    {
      href: `${userRoute}adv/basics`,
      title: localesData.adv.adv_basics,
      submenu: [],
    },
    {
      href: `${userRoute}adv/policy`,
      title: localesData.adv.adv_policy,
      submenu: [],
    },
  ];

  // GET USER PROFILE DATA
  const getUserProfileData = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getUserProfile);
      if (status === 200) {
        saveToStorage(identifiers.userData, data?.data);
        dispatch(userDetails(data?.data));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    auth && getUserProfileData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      <TopBar auth={auth} user_data={user_data} verify_count={verify_count} />
      <Box className='layout-container'>
        {!location?.pathname?.includes(`${userRoute}notification`) && (
          <AdvLeftMenus
            menusItems={menusItemsList}
            profileMenusItems={profileMenusItems}
            user_data={user_data}
          />
        )}
        <Box
          className={`adv-layout-content ${
            location?.pathname?.includes(`${userRoute}adv/profile`) ||
            location?.pathname?.includes(`${userRoute}adv/password`)
              ? 'adv-with-right-side-bar'
              : ''
          }`}
        >
          <Box className='adv-breadcrumbs-container'>
            <CustomInfoBreadcrumbs navigateList={breadcrumbMenus} />
            <Divider className='info-divider' />
          </Box>
          {/* <div className="layout-wrapper"> */}
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
          {/* </div> */}
        </Box>
        {(location?.pathname?.includes(`${userRoute}adv/profile`) ||
          location?.pathname?.includes(`${userRoute}adv/password`)) && (
          <Hidden mdDown>
            {/* <Box className="adv-right-side-container"> */}
            <AdvRightSide />
            {/* </Box> */}
          </Hidden>
        )}
      </Box>
    </div>
  );
};

export default AdvLayout;
