import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Avatar,
  Toolbar,
  makeStyles,
  Badge,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

import Logo from 'src/components/Logo';
import { IntlContext } from 'src/App';
import { ReactComponent as NotificationIcon } from 'src/assets/images/icons/notification.svg';
import { ReactComponent as MenuIcon } from 'src/assets/images/icons/menu.svg';
import { ReactComponent as SearchIcon } from 'src/assets/images/icons/search.svg';
// import { ReactComponent as HomeIcon } from 'src/assets/images/icons/homeicon.svg';
import { ReactComponent as HotRed } from 'src/assets/images/icons/hot-red.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified-icon.svg';
import noAvatar from 'src/assets/images/icons/default-profile.svg';

import { useDispatch, useSelector } from 'react-redux';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { socket } from 'src/helpers/context/socket';
import { notificationCount } from 'src/helpers/store/Actions/user';

import { CustomTextField } from 'src/views/component/UI/textfield';
import NotificationList from './Notification';
import AccountDetails from './AccountDetails';
import { Config } from 'src/helpers/context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
  },
}));

const TopBar = ({ auth, user_data, verify_count, className, ...rest }) => {
  const userRoute = Config?.userRoutePath;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const redux_notification_count = useSelector(
    (state) => state?.reduxData?.notificationCountData
  );
  let searchValue = new URLSearchParams(window.location.search).get('q');
  let searchText = searchValue
    ? searchValue
    : window.location.pathname.includes(`${userRoute}search`)
      ? searchValue
      : '';
  const isLogin = auth ? true : false;
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  // SEARCH LIST
  const getSearchData = async (value) => {
    const search = isLogin ? URLS.searchList + `?search=${value?.slice(0, 50)}` :
      URLS.searchListPublic + `?search=${value?.slice(0, 50)}`
    try {
      const { status, data } = await axiosInstance.get(search);
      if (status === 200) {
        setSearchList(data?.data);
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  //READ NOTIFICATION
  const handleUpdateNotification = async (id) => {
    try {
      const { status } = await axiosInstance.put(
        `${URLS.readNotification}/read`
      );
      if (status === 200) {
        // getNotification();
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (inputValue || search) getSearchData(inputValue);
    // eslint-disable-next-line
  }, [inputValue]);

  const searchField = () => {
    return (
      <>
        <Autocomplete
          id='free-solo-demo'
          freeSolo
          size='small'
          options={searchList?.map((item) => {
            return (
              item?.title ||
              item?.user_name ||
              item?.first_name ||
              item?.last_name
            );
          })}
          value={search || ''}
          inputValue={inputValue || ''}
          onChange={(e, newValue) => {
            setSearch(newValue);
            navigate(
              `${userRoute}search?q=${newValue ? newValue?.slice(0, 50) : ''}`
            );
          }}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              variant='outlined'
              className='custom-textfield header-search-field'
              placeholder={localesData?.home_page?.search_here}
              size='small'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() => setOpenSearch(false)}
                  >
                    {openSearch ? <Close /> : <SearchIcon />}
                    {/* <SearchIcon /> */}
                  </InputAdornment>
                ),
              }}
            />
          )}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
        />
      </>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleUpdateNotification();

    dispatch(notificationCount(0));
  };

  useEffect(() => {
    setSearch(searchText);
    setInputValue(searchText);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    searchText === '' && setSearchList([]);
  }, [searchText]);

  useEffect(() => {
    socket.on('CountSocket', (data) => {
      dispatch(notificationCount(data?.notificationCount));
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AppBar
        className={`${clsx(classes.root, className)} app-bar`}
        elevation={0}
        {...rest}
      >
        <Toolbar>
          {!auth && (
            <NavLink to={`${userRoute}`} className='logo-anchor'>
              <Logo />
            </NavLink>
          )}
          {/* <Box flexGrow={1} /> */}

          {auth ? (
            <Hidden smDown>
              <Box className='left-side-menu-block'>
                {!openSearch && (
                  <NavLink
                    to={
                      user_data?.subclass_id !== 3
                        ? userRoute
                        : `${userRoute}adv/campaign`
                    }
                    className='logo-anchor'
                  >
                    <Logo />
                  </NavLink>
                )}
                {user_data?.subclass_id !== 3 && (
                  <>
                    {/* HOME */}
                    {/* <Box className='appbar-menu-block'>
                      <Box
                        className={`appbar-user-block pointer appbar-home menu-hover ${
                          location.pathname === userRoute && 'menu-active'
                        }`}
                        onClick={() => navigate(userRoute)}
                      >
                        <IconButton className='app-bar-icon-btn app-bar-btn-home'>
                          <HomeIcon />
                        </IconButton>
                        <Typography variant='h5' className='hide-1024'>
                          {localesData?.home}
                        </Typography>
                      </Box>
                    </Box>  */}
                    {/* HOT */}
                    <Box className='appbar-menu-block appbar-trending'>
                      <Box
                        mr='10px'
                        className={`appbar-user-block pointer appbar-home menu-hover ${location.pathname ===
                          `${userRoute}trending-sezzyou` && 'menu-active'
                          }`}
                        onClick={() => navigate(`${userRoute}trending-sezzyou`)}
                      >
                        <IconButton className='app-bar-icon-btn app-bar-btn-home'>
                          <HotRed />
                        </IconButton>
                        <Typography variant='h5' className='hide-1024'>
                          {localesData?.home_page?.trending}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              {/* SEARCH */}
              {user_data?.subclass_id !== 3 && (
                <Box flexGrow={1.5}>{searchField()}</Box>
              )}
              {/* USER PROFILE */}
              <Box className='appbar-menu-block'>
                <Box
                  ml='10px'
                  className='appbar-user-block pointer menu-hover'
                  onClick={() => {
                    user_data?.subclass_id === 3
                      ? navigate(`${userRoute}adv/profile`)
                      : navigate(`${userRoute}openboard`);
                  }}
                >
                  <Typography
                    variant='h6'
                    className='font-weight-600 hide-1024 d-flex-center'
                  >
                    <span className='appbar-user-name'>{user_data?.name}</span>
                    {user_data?.verified_status && (
                      <VerifiedIcon className='verified-icons' />
                    )}
                  </Typography>
                  <IconButton className='app-bar-icon-btn avatar-btn'>
                    <Avatar
                      className='app-bar-avtar'
                      src={user_data?.avatar || noAvatar}
                      alt=''
                    />
                  </IconButton>
                </Box>
                {/* SETTINGS */}
                <IconButton
                  className='app-bar-icon-btn menu-hover'
                  id='account-menu-list'
                  onClick={(e) => handleClickMenu(e)}
                >
                  <MenuIcon />
                </IconButton>
                {/* NOTIFICATION */}
                <IconButton
                  className={`app-bar-icon-btn menu-hover ${location.pathname.includes(`${userRoute}notification`) &&
                    'menu-active'
                    }`}
                  id='notification-list'
                  onClick={(e) => handleClick(e)}
                >
                  <Badge
                    badgeContent={
                      redux_notification_count !== undefined
                        ? redux_notification_count
                        : user_data?.notification_count
                    }
                    // variant="dot"
                    color='error'
                    className='notification-count'
                    overlap='rectangular'
                  >
                    <NotificationIcon />
                  </Badge>
                </IconButton>
              </Box>
            </Hidden>
          ) : (
            <Box width={'31.27px'} />
          )}

          {auth && !openSearch && (
            <Hidden mdUp>
              <Box className='left-side-menu-block'>
                {!openSearch && (
                  <NavLink
                    to={
                      user_data?.subclass_id !== 3
                        ? userRoute
                        : `${userRoute}adv/campaign`
                    }
                    className='logo-anchor'
                  >
                    <Logo />
                  </NavLink>
                )}
              </Box>
              {user_data?.subclass_id !== 3 && (
                <>
                  {/* SEARCH */}
                  <IconButton
                    className='app-bar-icon-btn'
                    onClick={() => setOpenSearch(!openSearch)}
                  >
                    <SearchIcon />
                  </IconButton>
                  {/* HOT */}
                  <IconButton
                    className='app-bar-icon-btn'
                    onClick={() => navigate(`${userRoute}trending-sezzyou`)}
                  >
                    <HotRed />
                  </IconButton>
                </>
              )}
              {/* NOTIFICATION */}
              <IconButton
                className='app-bar-icon-btn'
                id='notification-list'
                onClick={(e) => handleClick(e)}
              >
                <Badge
                  badgeContent={
                    redux_notification_count !== undefined
                      ? redux_notification_count
                      : user_data?.notification_count
                  }
                  // variant="dot"
                  color='error'
                  className='notification-count'
                  overlap='rectangular'
                >
                  <NotificationIcon />
                </Badge>
              </IconButton>
              {/* USER PROFILE */}
              <IconButton
                className='app-bar-icon-btn'
                onClick={() => {
                  user_data?.subclass_id === 3
                    ? navigate(`${userRoute}adv/profile`)
                    : navigate(`${userRoute}openboard`);
                }}
              >
                <Avatar
                  className='app-bar-avtar'
                  src={user_data?.avatar || noAvatar}
                  alt=''
                />
              </IconButton>
              {/* MENU BAR */}
              <IconButton
                className='app-bar-icon-btn'
                id='account-menu-list'
                onClick={(e) => handleClickMenu(e)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
        </Toolbar>
        {anchorElMenu && (
          <AccountDetails
            localesData={localesData}
            handleCloseMenu={handleCloseMenu}
            anchorElMenu={anchorElMenu}
          />
        )}
        {anchorEl && (
          <NotificationList
            localesData={localesData}
            handleClose={handleClose}
            anchorEl={anchorEl}
            navigate={navigate}
            user_data={user_data}
          />
        )}
        <Hidden mdUp>
          {openSearch && (
            <Box className='search-mobile-view'>{searchField()}</Box>
          )}
        </Hidden>
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
