import Axios from "axios/index";
import { errorHandler, fetchFromStorage } from "../context";
import { identifiers } from "src/helpers/constants/identifier";
import { Config } from "../context/config";
import { EventEmitter } from 'src/helpers/EventEmitter';

const axiosInstance = Axios.create({
  baseURL: Config.baseURL,
  headers: { "Content-Type": "application/json" },
});
axiosInstance.interceptors.request.use((config) => {
  const token = fetchFromStorage(identifiers.authData);
  const clonedConfig = config;

  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "accept-language": fetchFromStorage(identifiers.acceptlanguage),
    };
  }

  return clonedConfig;
});
axiosInstance.interceptors.response.use(
  response => {
    updateHeaderResponse(response);
    return response;
  },
  error => {
    updateHeaderResponse(error.response);
    errorHandler(error);
    return Promise.reject(error);
  },
  config => {
    return config;
  },
);
const updateHeaderResponse = response => {
  const maintenance = response?.headers?.['sezzyou-maintenance-mode'];
  if (maintenance) {
    EventEmitter.dispatch('maintenanceMode', maintenance);
  }
  EventEmitter.dispatch('sezzyouerror', response);
};

export default axiosInstance;
