// import React, { useContext } from "react";
// import { Link, useLocation } from "react-router-dom";
// import {
//   makeStyles,
//   Box,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   CssBaseline,
//   withStyles,
//   Hidden,
//   Typography,
// } from "@material-ui/core";

// import { IntlContext } from "src/App";

// // SIDE DRAWER MENU

// const drawerWidth = 277;

// // SIDE DRAWER
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.gray,
//     display: "flex",
//     height: "100%",
//     overflow: "hidden",
//     width: "100%",
//   },
//   drawer: {
//     maxWidth: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: "nowrap",
//     "@media (max-width:768px)": {
//       maxWidth: "100%",
//     },
//   },
//   drawerOpen: {
//     position: "inherit",
//     left: "auto",
//     marginTop: "17px",
//     paddingBottom: "17px",
//     // padding: "18px",
//     width: drawerWidth,
//     background: "transparent",
//     border: 0,
//     borderRadius: "5px",
//     // height: "auto",
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuList: {
//     padding: 0,
//   },
// }));

// const MuiListItem = withStyles({
//   root: {
//     height: "45px",
//     padding: "15px 10px",
//     marginBottom: "3px",
//     borderRadius: "8px",
//     "& .MuiListItemText-root": {
//       color: "#191919",
//       "& .MuiTypography-root": {
//         fontSize: "18px",
//         // lineHeight: "21px",
//         letterSpacing: 0,
//         fontFamily: "Inter, sans-serif",
//       },
//     },
//     "&$selected": {
//       backgroundColor: "#4582C3",
//       color: "#ffffff",
//       "& .MuiListItemText-root": {
//         color: "#ffffff",
//       },
//     },
//     "&$selected:hover": {
//       backgroundColor: "#4582C3",
//       color: "#ffffff",
//       "& .MuiListItemText-root": {
//         color: "#ffffff",
//       },
//     },
//   },
//   selected: {},
// })(ListItem);

// const AdvRightSide = () => {
//   const classes = useStyles();
//   const location = useLocation();
//   const intlContext = useContext(IntlContext);
//   const localesData = intlContext?.messages;

//   const information_menu = [
//     {
//       href: "/info/platform",
//       title: localesData?.register?.about_sezz_you,
//       submenu: [],
//     },
//     {
//       href: "/help",
//       title: localesData?.register?.help_centre,
//       submenu: [],
//     },
//     {
//       href: "/announcements",
//       title: localesData?.info?.announcements,
//       submenu: [],
//     },
//   ];
//   const policy_Menu = [
//     {
//       href: "/agreement",
//       title: localesData?.register?.user_agreement,
//     },
//     {
//       href: "/privacy-policy",
//       title: localesData?.register?.privacy_policy,
//     },
//   ];

//   return (
//     <div className={classes.root}>
//       <Hidden mdDown>
//         <Box className="menu-drawer">
//           <CssBaseline />
//           <Drawer
//             variant="permanent"
//             className={classes.drawer}
//             classes={{
//               paper: classes.drawerOpen,
//             }}
//           >
//             <Box className="adv-right-side-menu">
//               {/* MENUS LIST */}
//               <Box className="adv-quick-link-menu">
//                 {/* INFO */}
//                 <Box className="adv-quick-link-block">
//                   <Typography variant="h4" className="adv-quick-link-title">
//                     {localesData?.adv?.quicklinks}
//                   </Typography>
//                 </Box>
//                 <List className="adv-menu-list">
//                   {information_menu?.map((item, i) => (
//                     <Box key={i}>
//                       <MuiListItem
//                         button
//                         component={Link}
//                         to={item?.href}
//                         selected={item?.href === location.pathname}
//                         disableRipple
//                       >
//                         <ListItemText>{item?.title}</ListItemText>
//                       </MuiListItem>
//                     </Box>
//                   ))}
//                   {policy_Menu?.map((item, i) => (
//                     <Box key={i}>
//                       <MuiListItem
//                         button
//                         component={Link}
//                         to={item?.href}
//                         selected={item?.href === location.pathname}
//                         disableRipple
//                       >
//                         <ListItemText>{item?.title}</ListItemText>
//                       </MuiListItem>
//                     </Box>
//                   ))}
//                 </List>
//               </Box>
//             </Box>
//           </Drawer>
//         </Box>
//       </Hidden>
//     </div>
//   );
// };

// export default AdvRightSide;

import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  withStyles,
  Hidden,
  Typography,
  Collapse,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { IntlContext } from 'src/App';
import { Config } from 'src/helpers/context';

// SIDE DRAWER MENU

const drawerWidth = 324;

// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  drawer: {
    maxWidth: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '@media (max-width:768px)': {
      maxWidth: '100%',
    },
  },
  drawerOpen: {
    left: 'auto',
    marginTop: '78px',
    paddingBottom: '78px',
    // padding: "18px",
    width: drawerWidth,
    background: 'transparent',
    border: 0,
    borderRadius: '5px',
    // height: "auto",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuList: {
    padding: 0,
  },
}));

const MuiListItem = withStyles({
  root: {
    height: '45px',
    padding: '15px 0px',
    marginBottom: '3px',
    borderRadius: '8px',
    '& .MuiListItemText-root': {
      color: '#191919',
      '& .MuiTypography-root': {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: 0,
        fontFamily: 'Inter, sans-serif',
      },
    },
    '&$selected': {
      backgroundColor: '#4582C3',
      color: '#ffffff',
      '& .MuiListItemText-root': {
        color: '#ffffff',
      },
    },
    '&$selected:hover': {
      backgroundColor: '#4582C3',
      color: '#ffffff',
      '& .MuiListItemText-root': {
        color: '#ffffff',
      },
    },
    '&:hover': {
      // backgroundColor: "#4DA4B3",
      // color: "#ffffff",
      // "& .MuiListItemText-root": {
      //   color: "#ffffff",
      // },
    },
  },
  selected: {},
})(ListItem);

const AdvRightSide = () => {
  const userRoute = Config?.userRoutePath;
  const classes = useStyles();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [openMenu, setOpenMenu] = useState({});

  const information_menu = [
    {
      href: '#',
      title: localesData?.register?.about_sezz_you,
      submenu: [
        {
          href: `${userRoute}info/platform`,
          title: localesData?.info_content?.info?.title,
          submenu: [],
        },
        {
          href: `${userRoute}info/features`,
          title: localesData?.info_content?.site?.title,
          submenu: [],
        },
        {
          href: `${userRoute}info/promise`,
          title: localesData?.info_content?.promise?.title,
          submenu: [],
        },
      ],
    },
    {
      href: `${userRoute}help`,
      title: localesData?.register?.help_centre,
      submenu: [
        {
          href: `${userRoute}help/getting-started`,
          title: localesData?.info_content?.help_center?.user_type?.title1_1,
          submenu: [],
        },
        {
          href: `${userRoute}help/home-info`,
          title: localesData?.info_content?.help_center?.home_page?.home,
          submenu: [],
        },
        {
          href: `${userRoute}help/trending`,
          title: localesData?.home_page?.trending,
          submenu: [],
        },
        {
          href: `${userRoute}help/platform-function`,
          title:
            localesData?.info_content?.help_center?.platform_function
              ?.platform_function,
          submenu: [],
        },
        {
          href: `${userRoute}help/your-section`,
          title:
            localesData?.info_content?.help_center?.your_section?.your_section,
          submenu: [],
        },
        {
          href: `${userRoute}help/your-account`,
          title:
            localesData?.info_content?.help_center?.your_account?.your_account,
          submenu: [],
        },
        {
          href: `${userRoute}help/verified-user`,
          title:
            localesData?.info_content?.help_center?.verified?.verified_users,
          submenu: [],
        },
        {
          href: `${userRoute}help/platform`,
          title:
            localesData?.info_content?.help_center?.your_account?.platform_noti
              ?.platform_notification,
          submenu: [],
        },
        {
          href: `${userRoute}help/contact-support`,
          title: localesData?.info_content?.help_center?.contact_us,
          submenu: [],
        },
      ],
    },
    {
      href: `${userRoute}announcements`,
      title: localesData?.info?.announcements,
      submenu: [],
    },
  ];
  const policy_Menu = [
    {
      href: `${userRoute}agreement`,
      title: localesData?.register?.user_agreement,
    },
    {
      href: `${userRoute}privacy-policy`,
      title: localesData?.register?.privacy_policy,
    },
  ];

  const handleClick = (item) => {
    setOpenMenu((prevState) => ({
      ...openMenu,
      [item?.title]: !prevState[item?.title],
    }));
  };

  const handleNestedMenus = (children) => {
    return children?.map((subOption) => {
      if (!subOption?.submenu) {
        return (
          <div key={subOption?.title}>
            <MuiListItem
              button
              component={Link}
              to={subOption?.href}
              selected={subOption?.href === location.pathname}
            >
              <ListItemText>{subOption?.title}</ListItemText>
              {/* <ListItemText primary={subOption?.name} /> */}
            </MuiListItem>
          </div>
        );
      }
      return (
        <div key={subOption?.title}>
          <MuiListItem
            button
            component={Link}
            to={subOption?.href}
            selected={subOption?.href === location.pathname}
            onClick={() => handleClick(subOption)}
          >
            {subOption?.submenu?.length !== 0 ? (
              openMenu[subOption?.title] ? (
                <ArrowDropUp />
              ) : (
                <ArrowDropDown />
              )
            ) : (
              <Box style={{ marginLeft: '25px' }}></Box>
            )}
            {/* <ListItemText primary={subOption?.name} /> */}
            <ListItemText>{subOption?.title}</ListItemText>
          </MuiListItem>
          <Collapse
            in={openMenu[subOption?.title]}
            timeout='auto'
            unmountOnExit
          >
            <Box className='submenu-option'>
              {handleNestedMenus(subOption?.submenu)}
            </Box>
          </Collapse>
        </div>
      );
    });
  };
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Box className='menu-drawer'>
          <CssBaseline />
          <Drawer
            variant='permanent'
            className={classes.drawer}
            classes={{
              paper: classes.drawerOpen,
            }}
          >
            <Box className='right-side-menu'>
              {/* MENUS LIST */}
              <Box className='information-menu'>
                {/* INFO */}
                <Box className='ac-info-block'>
                  <Typography variant='h5' className='font-22 ac-info-title'>
                    {localesData.info.information}
                  </Typography>
                </Box>
                <List className='menu-list'>
                  {/* {information_menu?.map((item, i) => (
                    <Box key={i}>
                      <MuiListItem
                        button
                        component={Link}
                        to={item?.href}
                        selected={item?.href === location.pathname}
                        disableRipple
                      >
                        <ListItemText>{item?.title}</ListItemText>
                      </MuiListItem>
                    </Box>
                  ))} */}
                  <Box>{handleNestedMenus(information_menu)}</Box>
                </List>
              </Box>
              <Box className='information-menu'>
                {/* POLICIES */}
                <Box className='ac-policy-block'>
                  <Typography variant='h5' className='font-22 ac-policy-title'>
                    {localesData.info.policies}
                  </Typography>
                </Box>
                <List className='menu-list'>
                  {policy_Menu?.map((item, i) => (
                    <Box key={i}>
                      <MuiListItem
                        button
                        component={Link}
                        to={item?.href}
                        selected={item?.href === location.pathname}
                        disableRipple
                      >
                        <ListItemText>{item?.title}</ListItemText>
                      </MuiListItem>
                    </Box>
                  ))}
                </List>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Hidden>
    </div>
  );
};

export default AdvRightSide;
