import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IntlContext } from "src/App";

const HomeInfo = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {
                localesData?.info_content?.help_center?.your_account
                  ?.platform_noti?.platform_notification
              }
            </Typography>

            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account
                    ?.platform_noti?.desc2,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account
                    ?.platform_noti?.desc3,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account
                    ?.platform_noti?.desc4,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account
                    ?.platform_noti?.desc5,
              }}
            />
            <Typography
              variant="h5"
              className="mb-17"
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.info_content?.help_center?.your_account
                    ?.platform_noti?.desc6,
              }}
            />
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_1,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc1_1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc1_2,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_2,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc2_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_3,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc3_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_4,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc4_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_5,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc5_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_6,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc6_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_7,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc7_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.head_8,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.your_account
                        ?.platform_noti?.desc8_1,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeInfo;
