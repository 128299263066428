export const Config = {
  baseURL: process.env.REACT_APP_API_URL,
  mediaURL: process.env.REACT_APP_MEDIA_URL,
  OnesignalAppID: process.env.REACT_APP_ONESIGNAL_APP_ID,
  socketPath: process.env.REACT_APP_API_SOCKET_PATH,
  socketUrl: process.env.REACT_APP_API_SOCKET_BASE_URL,
  stripeAPIKEYSK: process.env.REACT_APP_STRIPE_APIKEY_SK,
  siteVersion: process.env.REACT_APP_VERSION,
  iscomingsoon: process.env.REACT_APP_IS_COMING_SOON,
  userRoutePath: process.env.REACT_APP_ROUTE || '/',
  issueProxy: process.env.REACT_APP_API_SOCKET_BASE_URL
};
