import {
  AUTH_USER,
  USER_DETAILS,
  SELECT_INFO_MENU,
  USER_PROFULE_DETAILS,
  SEARCH,
  USER_MEDIA_DETAILS,
  SPONSORED_ADVERTISEMENT,
  NOTIFICATION_COUNT,
  TRENDING_LIST,
  QUICK_LINK_LIST,
  VIEW_TOPIC_POLL,
  INTEREST_LIST,
  SUGGESTED_LISTEN,
  NEWS_LIST,
  NEWS_LIST_DATA
} from "../constants";

export default function reduxData(state = [], action) {
  switch (action.type) {
    case AUTH_USER:
      return { authData: action.data };
    case USER_DETAILS:
      return { ...state, userData: action.data };
    case SELECT_INFO_MENU:
      return { ...state, selectInfoMenu: action.data };
    case USER_PROFULE_DETAILS:
      return { ...state, userProfileData: action.data };
    case SEARCH:
      return { ...state, searchdata: action.data };
    case USER_MEDIA_DETAILS:
      return { ...state, mediaData: action.data };
    case SPONSORED_ADVERTISEMENT:
      return { ...state, sponsoredAd: action.data };
    case NOTIFICATION_COUNT:
      return { ...state, notificationCountData: action.data };
    case TRENDING_LIST:
      return { ...state, trendingData: action.data };
    case QUICK_LINK_LIST:
      return { ...state, quicklinkData: action.data };
    case VIEW_TOPIC_POLL:
      return { ...state, topicPollData: action.data };
    case INTEREST_LIST:
      return { ...state, interestList: action.data };
    case SUGGESTED_LISTEN:
      return { ...state, listenUpdate: action.data };
    case NEWS_LIST:
      return { ...state, newsList: action.data };
    case NEWS_LIST_DATA:
      return { ...state, newsData: action.data };
    default:
      return { state: [] };
  }
}
