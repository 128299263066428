import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IntlContext } from 'src/App';
import 'src/views/component/application/Info/info.scss';
import { Config } from 'src/helpers/context';

const Help = () => {
  const userRoute = Config?.userRoutePath;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className='main-content-block'>
      <Grid container className='main-grid-container main-section-block'>
        <Grid item xs={12} sm={12} md={12} lg={12} className=''>
          <Box className='plateform_top'>
            <Typography
              variant='h3'
              className='info_head info_head_wrap'
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.help_center?.title,
              }}
            />
            <Typography
              variant='h5'
              className='mb-17 des_text'
              dangerouslySetInnerHTML={{
                __html: localesData?.info_content?.help_center?.desc1,
              }}
            />
            <Typography variant='h5' className='mb-17'>
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.help_center?.desc2,
                }}
              />
              <Link
                to={`${userRoute}help/contact-support`}
                className='link_text'
              >
                {' '}
                {localesData?.info_content?.help_center?.contact_us}
              </Link>
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Help;
