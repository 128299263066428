import React, { useState, useContext, useEffect } from 'react';
import { Box, MenuItem, Menu, Typography, IconButton } from '@material-ui/core';
import { IntlContext } from 'src/App';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { selectedInfoLeftMenu } from 'src/helpers/store/Actions/menu';

import { ReactComponent as ArrowForwardIcon } from 'src/assets/images/icons/arrow-forward.svg';
import { ReactComponent as DropDownIcon } from 'src/assets/images/icons/drop-down-icon.svg';
import { ReactComponent as HomeIcon } from 'src/assets/images/icons/homeicon.svg';

import './infoBreadcrumb.scss';
import { Config } from 'src/helpers/context';

const CustomInfoBreadcrumbs = ({ navigateList }) => {
  const userRoute = Config?.userRoutePath;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [breadcrumbDropDown, setBreadcrumbDropDown] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const pathname = location?.pathname;

  const policy_Menu = [
    {
      href: `${userRoute}agreement`,
      title: localesData?.register?.user_agreement,
    },
    {
      href: `${userRoute}privacy-policy`,
      title: localesData?.register?.privacy_policy,
    },
  ];

  const getAllParents = (arr, pathname, res) => {
    for (const el of arr) {
      if (el?.href === pathname) {
        res.unshift(el);
        return true;
      } else {
        let is_parent = getAllParents(el?.submenu, pathname, res);
        if (is_parent) {
          res.unshift(el);
          return true;
        }
      }
    }
  };
  useEffect(() => {
    let res = [];
    if (navigateList?.length) {
      if (breadcrumb?.length === 0) {
        getAllParents(navigateList, pathname, res);
        setBreadcrumb(res);
        dispatch(selectedInfoLeftMenu(res));
      }
    }
    // eslint-disable-next-line
  }, [navigateList]);

  useEffect(() => {
    let res = [];
    getAllParents(navigateList, pathname, res);
    setBreadcrumb(res);
    dispatch(selectedInfoLeftMenu(res));
    // eslint-disable-next-line
  }, [location]);

  const handleOpenMenu = (event, item) => {
    if (item !== undefined && item) {
      setBreadcrumbDropDown(item?.submenu);
    } else {
      setBreadcrumbDropDown([...navigateList, ...policy_Menu]);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const redirectTopics = (item) => {
    if (item?.href === '#') {
      navigate(item?.submenu[0]?.href);
    } else {
      navigate(item?.href);
    }
    setAnchorEl(null);
  };

  const breadcrumbDropDownList = () => {
    return (
      <Box className='menu_wrap'>
        <Menu
          elevation={0}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClickAway}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'end',
            horizontal: 'center',
          }}
          className='menu_wrap'
        >
          {breadcrumbDropDown?.map((item, i) => (
            <Box key={i}>
              <MenuItem
                className='breadcrumbs-menu-item'
                onClick={() => redirectTopics(item)}
              >
                {item?.title}
              </MenuItem>
            </Box>
          ))}
        </Menu>
      </Box>
    );
  };

  return (
    <Box className='custom-info-breadcrumbs'>
      <Box className="custom-breadcrumbs pt-0">
        <IconButton onClick={() => navigate(userRoute)} className={pathname?.includes(`${userRoute}agreement`) ||
          pathname?.includes(`${userRoute}privacy-policy`) ? "app-bar-btn-home p-0" : breadcrumb?.length === 0 ? "app-bar-btn-home pointer active-icon p-0" : "app-bar-btn-home p-0"} >
          <HomeIcon />
        </IconButton>
        <Box
          className='breadcrumbs-menu pointer'
          onClick={(e) => handleOpenMenu(e)}
        >
          <Typography
            variant='h5'
            className={
              breadcrumb?.length === 0 &&
                pathname?.includes(`${userRoute}agreement`) &&
                pathname?.includes(`${userRoute}privacy-policy`)
                ? 'last-breadcrumb'
                : 'active-breadcrumb'
            }
          >
            {localesData.sezz_you}
          </Typography>
          <DropDownIcon />
        </Box>
        {(pathname?.includes(`${userRoute}agreement`) ||
          pathname?.includes(`${userRoute}privacy-policy`)) && (
            <Box className='breadcrumbs-menu'>
              <ArrowForwardIcon className='separator-icon' />
              <Link
                className={
                  pathname?.includes(`${userRoute}agreement`) ||
                    pathname?.includes(`${userRoute}privacy-policy`)
                    ? 'last-breadcrumb'
                    : 'active-breadcrumb'
                }
                to={
                  pathname?.includes(`${userRoute}agreement`)
                    ? `${userRoute}agreement`
                    : `${userRoute}privacy-policy`
                }
                id={`breadcrumb`}
              >
                {pathname?.includes(`${userRoute}agreement`)
                  ? localesData?.register?.user_agreement
                  : localesData?.register?.privacy_policy}
              </Link>
            </Box>
          )}
        {breadcrumb?.map((item, i) => {
          return (
            <Box
              className='breadcrumbs-menu pointer'
              key={i}
              onClick={(e) =>
                item?.submenu?.length !== 0 && handleOpenMenu(e, item)
              }
            >
              <ArrowForwardIcon className='separator-icon' />
              <Typography
                variant='h5'
                className={
                  item?.href === pathname
                    ? 'last-breadcrumb'
                    : 'active-breadcrumb'
                }
                id={`breadcrumb-${i}`}
              >
                {item?.title}
              </Typography>
              {item?.submenu?.length !== 0 && <DropDownIcon />}
            </Box>
          );
        })}
        {anchorEl && breadcrumbDropDownList()}
      </Box>
    </Box>
  );
};

export default CustomInfoBreadcrumbs;
