import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { identifiers } from 'src/helpers/constants/identifier';
import { sponsoredAdDetails } from 'src/helpers/store/Actions/advertise';

import { fetchFromStorage, saveToStorage } from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from 'src/helpers/store/Actions/user';

import TopBar from './TopBar';
import PublicTopBar from './PublicTopbar';

import 'src/layouts/layout.scss';

// SIDE DRAWER
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.light,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  signinroot: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    background: '#fafafb'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  layoutWrapper: {
    marginTop: 0
  }
}));

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const location = useLocation();

  const redux_auth = useSelector(state => state?.reduxData?.authData);
  const redux_userData = useSelector(state => state?.reduxData?.userData);
  const user_data = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers.userData);

  const auth = redux_auth ? redux_auth : fetchFromStorage(identifiers.authData);
  const isLogin = auth ? true : false;

  const verify_count = useSelector(
    state => state?.reduxData?.user_profile_count
  );

  // GET USER PROFILE DATA
  const getUserProfileData = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getUserProfile);
      if (status === 200) {
        saveToStorage(identifiers.userData, data?.data);
        dispatch(userDetails(data?.data));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // ADVERTISE LIST
  const getAdvertisList = async () => {
    const sponsoredUrl = isLogin ? URLS.sponsored : URLS.sponsoredPublic;
    try {
      const { status, data } = await axiosInstance.get(sponsoredUrl);
      if (status === 200) {
        let adList = data?.data;
        let frames = [];
        if (Object.keys(adList).length !== 0) {
          frames = [adList?.frame1, adList?.frame2, adList?.frame3]?.filter(
            o => o !== ''
          );
        }
        dispatch(sponsoredAdDetails({ ...adList, frames }));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isLogin) {
        getUserProfileData();
      }
      getAdvertisList();
    }, 200);
    // eslint-disable-next-line
  }, [isLogin]);
  return (
    <>
      {location?.pathname.includes('/server-down') ? (
        <div className={classes.root}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      ) : location?.pathname.includes('/sign-in/') ? (
        <div className={classes.signinroot}>
          <ToastContainer style={{ padding: '5px' }} limit={1} />
          <div className={classes.layoutWrapper + ' layout-wrapper'}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <ToastContainer style={{ padding: '5px' }} limit={1} />
          {auth ? (
            <TopBar
              auth={auth}
              user_data={user_data}
              verify_count={verify_count}
            />
          ) : (
            <PublicTopBar />
          )}
          <div className="layout-wrapper">
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
