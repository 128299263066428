import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  makeStyles,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  withStyles,
  Hidden,
  Collapse,
  Typography,
  Divider,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { IntlContext } from "src/App";

const drawerWidth = 324;

// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    left: "auto",
    marginTop: "80px",
    paddingBottom: "100px",
    width: drawerWidth,
    background: "transparent",
    border: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuList: {
    paddingTop: "17px",
    background: "#E9EDF1",
    height: "100vh",
    overflow: "auto",
  },
}));

// SIDE DRAWER MENU
const MuiListItem = withStyles({
  root: {
    width: "auto",
    borderRadius: "8px",
    margin: "0px 18px 3px 18px",
    height: "45px",
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontSize: "18px",
        lineHeight: "21px",
        letterSpacing: 0,
        color: "#191919",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontFamily: "Inter, sans-serif",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "&$selected": {
      background: "linear-gradient(to bottom, #4582C3, #45C1C3)",
      color: "#ffffff",
      // marginRight: "18px",
      "& .MuiListItemText-root": {
        "& .MuiTypography-root": {
          color: "#ffffff",
        },
      },
    },
  },
  selected: {},
})(ListItem);

const AdvLeftMenus = ({ menusItems, profileMenusItems, user_data }) => {
  const classes = useStyles();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState({});
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const handleClick = (item) => {
    setOpenMenu((prevState) => ({
      ...openMenu,
      [item?.title]: !prevState[item?.title],
    }));
  };

  const handleNestedMenus = (children) => {
    return children?.map((subOption) => {
      if (!subOption?.submenu) {
        return (
          <React.Fragment key={subOption?.title}>
            <MuiListItem
              button
              component={Link}
              to={subOption?.href}
              selected={subOption?.href === location.pathname}
            >
              <ListItemText>{subOption?.title}</ListItemText>
            </MuiListItem>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={subOption?.title}>
          <MuiListItem
            button
            component={Link}
            to={subOption?.href}
            selected={subOption?.href === location.pathname}
            onClick={() => handleClick(subOption)}
          >
            {subOption?.submenu?.length !== 0 &&
              (openMenu[subOption?.title] ? (
                <ArrowDropUp />
              ) : (
                <ArrowDropDown />
              ))}
            <ListItemText>{subOption?.title}</ListItemText>
          </MuiListItem>
          <Collapse in={openMenu[subOption?.title]} timeout="auto">
            <Box className="submenu-option">
              {handleNestedMenus(subOption?.submenu)}
            </Box>
          </Collapse>
        </React.Fragment>
      );
    });
  };

  return (
    <Hidden mdDown>
      <Box className="adv-left-menu-drawer">
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerOpen,
          }}
        >
          {/* HEADING */}
          <Box className="adv-block">
            <Typography variant="h5" className="font-22 adv-title">
              {localesData.adv.adv_heading}
            </Typography>
          </Box>
          {/* MENUS LIST */}
          <List className={classes.menuList}>
            {user_data && user_data?.subclass_id === 3 ? (
              <>
                <Box>{handleNestedMenus(profileMenusItems)}</Box>
                <Divider className="adv-account-sidbar-divider" />
              </>
            ) : null}
            <Box>{handleNestedMenus(menusItems)}</Box>
          </List>
        </Drawer>
      </Box>
    </Hidden>
  );
};

export default AdvLeftMenus;
