import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, Box, Divider } from '@material-ui/core';
import { IntlContext } from 'src/App';
import { ToastContainer } from 'react-toastify';
import { identifiers } from 'src/helpers/constants/identifier';

import { Config, fetchFromStorage, saveToStorage } from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';

import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from 'src/helpers/store/Actions/user';

import TopBar from '../MainLayout/TopBar';
import InfoLeftMenu from './InfoLeftMenu';
import CustomInfoBreadcrumbs from 'src/views/component/UI/InfoBreadcrumbs';

import 'src/layouts/layout.scss';

// SIDE DRAWER
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.gray,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    background: '#ffffff',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const InfoLayout = () => {
  const userRoute = Config?.userRoutePath;
  const dispatch = useDispatch();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.reduxData?.authData);
  const redux_userData = useSelector((state) => state?.reduxData?.userData);
  const user_data = redux_userData
    ? redux_userData
    : fetchFromStorage(identifiers.userData);
  const auth = redux_auth ? redux_auth : fetchFromStorage(identifiers.authData);

  const verify_count = useSelector(
    (state) => state?.reduxData?.user_profile_count
  );

  const menusItems = [
    {
      href: '#',
      title: localesData?.register?.about_sezz_you,
      submenu: [
        {
          href: `${userRoute}info/platform`,
          title: localesData?.info_content?.info?.title,
          submenu: [],
        },
        {
          href: `${userRoute}info/features`,
          title: localesData?.info_content?.site?.title,
          submenu: [],
        },
        // {
        //   href: "/info/users",
        //   title: localesData?.info_content?.user?.title,
        //   submenu: [],
        // },
        {
          href: `${userRoute}info/promise`,
          title: localesData?.info_content?.promise?.title,
          submenu: [],
        },
      ],
    },
    {
      href: `${userRoute}help`,
      title: localesData?.register?.help_centre,
      submenu: [
        {
          href: `${userRoute}help/getting-started`,
          title: localesData?.info_content?.help_center?.user_type?.title1_1,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/usertypes",
          //     title:
          //       localesData?.info_content?.help_center?.user_type?.title1_2,
          //     submenu: [],
          //   },
          //   {
          //     href: "#",
          //     title:
          //       localesData?.info_content?.help_center?.individual
          //         ?.user_functionality,
          //     submenu: [
          //       {
          //         href: "/help/userfunc-ind",
          //         title:
          //           localesData?.info_content?.help_center?.individual
          //             ?.individual_users,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/userfunc-group",
          //         title:
          //           localesData?.info_content?.help_center?.group?.group_users,
          //         submenu: [],
          //       },
          //     ],
          //   },
          //   {
          //     href: "#",
          //     title:
          //       localesData?.info_content?.help_center?.registration
          //         ?.registration,
          //     submenu: [
          //       {
          //         href: "/help/reg-ind",
          //         title:
          //           localesData?.info_content?.help_center?.individual
          //             ?.individual_users,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/reg-group",
          //         title:
          //           localesData?.info_content?.help_center?.group?.group_users,
          //         submenu: [],
          //       },
          //     ],
          //   },
          //   {
          //     href: "/help/login",
          //     title:
          //       localesData?.info_content?.help_center?.loggedin?.logging_in,
          //     submenu: [
          //       {
          //         href: "/help/pwd-reset",
          //         title:
          //           localesData?.info_content?.help_center?.loggedin?.resep_pass
          //             ?.password_reset,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/keep-loggedin",
          //         title:
          //           localesData?.info_content?.help_center?.loggedin?.keep_login
          //             ?.keep_me_logged_in,
          //         submenu: [],
          //       },
          //     ],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/home-info`,
          title: localesData?.info_content?.help_center?.home_page?.home,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/page-summary",
          //     title: localesData?.info_content?.help_center?.home_page?.summary,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/page-recent",
          //     title:
          //       localesData?.info_content?.help_center?.home_page?.news
          //         ?.news_feed,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/page-topicpoll",
          //     title:
          //       localesData?.info_content?.help_center?.home_page?.topic_poll
          //         ?.topics_polls_news,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/page-links",
          //     title:
          //       localesData?.info_content?.help_center?.home_page?.quick_links
          //         ?.quick_links,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/page-trending",
          //     title:
          //       localesData?.info_content?.help_center?.home_page?.trending
          //         ?.trending,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/page-people",
          //     title:
          //       localesData?.info_content?.help_center?.sezzme_sezzwe
          //         ?.sezzme_sezzwe,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/trending`,
          title: localesData?.home_page?.trending,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/trending-summary",
          //     title: localesData?.info_content?.help_center?.home_page?.summary,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-sezz-topic",
          //     title:
          //       localesData?.info_content?.help_center?.trending
          //         ?.sezzyou_topics_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-ob-topic",
          //     title: localesData?.search_option?.open_board_topics,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-poll",
          //     title: localesData?.search_option?.polls,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-video",
          //     title: localesData?.videos,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-photo",
          //     title: localesData?.photos,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/trending-user",
          //     title: localesData?.home_page?.users,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/platform-function`,
          title:
            localesData?.info_content?.help_center?.platform_function
              ?.platform_function,
          submenu: [],
          // submenu: [
          //   // {
          //   //   href: "/help/func-find",
          //   //   title: "Find & Listen",
          //   //   submenu: [],
          //   // },
          //   // {
          //   //   href: "/help/func-listen",
          //   //   title: "Listen",
          //   //   submenu: [],
          //   // },
          //   {
          //     href: "/help/func-invite",
          //     title: localesData?.main_layout?.invite,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-like",
          //     title: localesData?.like,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-search",
          //     title: localesData?.info_content?.help_center?.site_func?.search,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-vote",
          //     title:
          //       localesData?.info_content?.help_center?.site_func?.vote_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-conversation",
          //     title:
          //       localesData?.info_content?.help_center?.site_func
          //         ?.start_conversation_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-comment",
          //     title:
          //       localesData?.info_content?.help_center?.site_func
          //         ?.post_comment_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-broadcast",
          //     title: localesData?.sezzme_menu?.broadcast_conversations,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-favorite",
          //     title:
          //       localesData?.info_content?.help_center?.sezzme_sezzwe
          //         ?.individual?.fav_conv_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-report",
          //     title:
          //       localesData?.info_content?.help_center?.site_func?.report_title,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-block",
          //     title:
          //       localesData?.info_content?.help_center?.site_func?.block_user,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/func-addtomy",
          //     title:
          //       localesData?.info_content?.help_center?.site_func?.add_my_title,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/your-section`,
          title:
            localesData?.info_content?.help_center?.your_section?.your_section,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/sezz-summary",
          //     title: localesData?.info_content?.help_center?.home_page?.summary,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/sezz-me",
          //     title:
          //       localesData?.info_content?.help_center?.sezzme_sezzwe
          //         ?.individual?.individual,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/sezz-we",
          //     title:
          //       localesData?.info_content?.help_center?.sezzme_sezzwe?.group
          //         ?.group,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/your-account`,
          title:
            localesData?.info_content?.help_center?.your_account?.your_account,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/account-overview",
          //     title:
          //       localesData?.info_content?.help_center?.your_account
          //         ?.my_our_account,
          //     submenu: [],
          //   },
          //   {
          //     href: "#",
          //     title:
          //       localesData?.info_content?.help_center?.your_account?.account
          //         ?.individual_users,
          //     submenu: [
          //       {
          //         href: "/help/account-ind-profile",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.account?.account_profile,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-pwd",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.password?.password,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-interest",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.interests?.my_interests,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-email",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.email_noti?.email_notification,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-site",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.site_noti?.site_notification,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-other",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.other
          //             ?.other_settings,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-link",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.linked_to?.linked_to,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-block",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.blocked?.blocked,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-verified",
          //         title: localesData?.main_layout?.verified_status,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-ind-close",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.close
          //             ?.close,
          //         submenu: [],
          //       },
          //     ],
          //   },
          //   {
          //     href: "#",
          //     title:
          //       localesData?.info_content?.help_center?.your_account?.account
          //         ?.group_users,
          //     submenu: [
          //       {
          //         href: "/help/account-group-profile",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.account?.account_profile,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-pwd",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.password?.password,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-topic",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.topics
          //             ?.our_open_board_topics,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-poll",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.poll
          //             ?.our_polls,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-comp",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.our_competitions?.our_competitions,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-ppl",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.people
          //             ?.our_people,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-event",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.events
          //             ?.our_events,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-email",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.email_noti?.email_notification,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-site",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.site_noti?.site_notification,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-other",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.other
          //             ?.other_settings,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-block",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.blocked?.group_block,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-verified",
          //         title:
          //           localesData?.info_content?.help_center?.your_account
          //             ?.verified?.group_status,
          //         submenu: [],
          //       },
          //       {
          //         href: "/help/account-group-close",
          //         title:
          //           localesData?.info_content?.help_center?.your_account?.close
          //             ?.close,
          //         submenu: [],
          //       },
          //     ],
          //   },
          // ],
        },

        {
          href: `${userRoute}help/verified-user`,
          title:
            localesData?.info_content?.help_center?.verified?.verified_users,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/verified-summary",
          //     title: localesData?.info_content?.help_center?.home_page?.summary,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/verified-ind",
          //     title:
          //       localesData?.info_content?.help_center?.verified
          //         ?.verified_individuals,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/verified-group",
          //     title:
          //       localesData?.info_content?.help_center?.verified
          //         ?.verified_groups,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/platform`,
          title:
            localesData?.info_content?.help_center?.your_account?.platform_noti
              ?.platform_notification,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/notification-summary",
          //     title: localesData?.info_content?.help_center?.home_page?.summary,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/notification-ind",
          //     title:
          //       localesData?.info_content?.help_center?.individual
          //         ?.individual_users,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/notification-group",
          //     title: localesData?.info_content?.help_center?.group?.group_users,
          //     submenu: [],
          //   },
          // ],
        },
        {
          href: `${userRoute}help/contact-support`,
          title: localesData?.info_content?.help_center?.contact_us,
          submenu: [],
          // submenu: [
          //   {
          //     href: "/help/contact-reg",
          //     title:
          //       localesData?.info_content?.help_center?.registration
          //         ?.registration,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-account",
          //     title: localesData?.info_content?.help_center?.contact?.ac_access,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-sezzme",
          //     title: localesData?.info_content?.help_center?.contact?.sezzme,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-sezzwe",
          //     title: localesData?.info_content?.help_center?.contact?.sezzwe,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-feature",
          //     title:
          //       localesData?.info_content?.help_center?.contact?.site_features,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-report",
          //     title: localesData?.info_content?.help_center?.contact?.report,
          //     submenu: [],
          //   },
          //   {
          //     href: "/help/contact-other",
          //     title: localesData?.info_content?.help_center?.contact?.other,
          //     submenu: [],
          //   },
          // ],
        },
      ],
    },
    // {
    //   href: "/app",
    //   title: localesData?.register?.sezz_you_app,
    //   submenu: [],
    // },
    {
      href: `${userRoute}announcements`,
      title: localesData?.info?.announcements,
      submenu: [],
    },
  ];

  // GET USER PROFILE DATA
  const getUserProfileData = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getUserProfile);
      if (status === 200) {
        saveToStorage(identifiers.userData, data?.data);
        dispatch(userDetails(data?.data));
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    auth && getUserProfileData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      <TopBar auth={auth} user_data={user_data} verify_count={verify_count} />
      <Box className='layout-container'>
        <InfoLeftMenu menusItems={menusItems} />
        <Box className='layout-content'>
          <CustomInfoBreadcrumbs navigateList={menusItems} />
          <Divider className='info-divider' />
          <div className='layout-wrapper'>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default InfoLayout;
