import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "../info.scss";

const PrivacyPolicy = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <>
      <Box className="main-content-block">
        <Grid container className="main-grid-container main-section-block">
          <Grid item xs={12} sm={12} md={12} lg={12} className="">
            <Box className="des_text user_agreement">
              <Typography variant="h5" className="agreement_head font-22">
                {localesData?.policy?.title}
              </Typography>
              <Box className="agreement_text">
                <Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head1}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description1,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head2}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description2,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head3}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description3,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head4}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description4,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head5}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description5,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head6}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description6,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head7}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description7,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head8}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description8,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head9}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description9,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head10}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description10,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head11}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description11,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" className="scope_text mb-17">
                    {localesData?.policy?.head12}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h5"
                      className="mb-17"
                      dangerouslySetInnerHTML={{
                        __html: localesData?.policy?.description12,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default PrivacyPolicy;
