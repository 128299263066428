import { Config } from "../context/config";
export const URLS = {
  getUserType: "/v1/public/auth/type",
  getCountry: "/v1/public/country/getCountry",
  getState: "/v1/public/country/getRegion1",
  getCity: "/v1/public/country/getRegion2",
  getNationalitys: "/v1/public/country/nation",
  getCategory: "/v1/public/auth/category",
  sendCode: "/v1/public/auth/send-code",
  usernameVerify: "/v1/public/auth/checkUsername",
  getUserlist: "/v1/public/user/list",
  // AUTH MODULE
  signIn: "/v1/public/auth/login",
  individualSignUp: "/v1/public/auth/register",
  groupSignUp: "/v1/public/auth/group/register",
  verifyOTP: "/v1/public/user/verifyOtp",
  resendOTP: "/v1/public/user/resendOtp",
  resetPassword: "/v1/public/auth/forgot",
  forgotPassword: "/v1/public/auth/forgot",
  socialLogin: "/v1/public/user/sociallogin",
  registerDeviceId: "/v1/private/user/registerDeviceId",
  logout: "/v1/private/user/logout",

  // USER URL
  getUserProfile: "/v1/private/user/profile",
  getTimeZone: "/v1/public/country/timezone",
  interests: "/v1/private/user/interest",
  changePassword: "/v1/private/user/change-password",
  changePhoneNumber: "/v1/private/user/change-phone",
  changeName: "/v1/private/user/change-name",
  userSetting: "/v1/private/user/setting",
  userBlocked: "/v1/private/user/blacklist",
  linkedTo: "/v1/private/user/linkedto",
  userGroup: "/v1/private/user/group",
  myGroup: "/v1/private/user/myGroup",
  userAddBlocked: "/v1/private/user/block",
  verificationRequest: "/v1/private/user/verificationRequest",
  getVerificationStatus: "/v1/private/user/verificationStatus",
  emailConfirmation: "/v1/public/auth/confirm",
  invite: "/v1/private/user/invite",
  quickLink: "/v1/private/user/myLink",
  getNewsList: "/v1/private/user/news",
  getOnePollList: "/v1/private/poll",
  addPollLike: "/v1/private/poll/addLike",
  addMyPoll: "/v1/private/poll/addMyPoll",
  removeMyPoll: "/v1/private/poll/removeMyPoll",
  addvote: "/v1/private/poll/addvote",
  topicsList: "/v1/private/topic/list",
  getOneTopicList: "/v1/private/topic",
  addTopicLike: "/v1/private/topic/addLike",
  addMyTopic: "/v1/private/topic/addMyTopic",
  removeTopic: "/v1/private/topic/removeMyTopic",
  myTopics: "/v1/private/topic/mytopics",
  addBoardLike: "/v1/private/board/addLike",
  addFavorite: "/v1/private/board/addFavorite",
  removeFavorite: "/v1/private/board/removeFavorite",
  addBroadcast: "/v1/private/board/addBroadcast",
  removeBroadcast: "/v1/private/board/removeBroadcast",
  boardDetails: "/v1/private/board/list",
  userByName: "/v1/private/user/u",
  userListen: "/v1/private/user/listen",
  addQuickLink: "/v1/private/user/addLink",
  removeQuickLink: "/v1/private/user/removeLink",
  createConversation: "/v1/private/board/create",
  addComment: "/v1/private/board/comment",
  mediaLike: "/v1/private/board/mediaLike",
  getPollList: "/v1/private/poll/mypolls",
  removePoll: "/v1/private/poll/removeMyPoll",
  changeEmail: "/v1/private/user/change-email",
  getMedia: "/v1/private/user/media",
  getTopicMedia: "/v1/private/topic/media",
  announcement: "/v1/public/user/announcement",
  searchList: "/v1/private/user/search",
  getNotification: "/v1/private/message/list",
  readNotification: "/v1/private/message",
  getTrending: "/v1/private/user/widget",
  trendingContributors: "/v1/private/user/trending",
  suggested: "/v1/private/user/suggested",
  getComments: "/v1/private/board/comments",
  // TICKET URL
  closeAccount: "/v1/private/user/close-account",
  cancelAccount: "/v1/private/user/cancel-close",
  ticketDetails: "/v1/public/user/support",
  report: "/v1/private/user/report",
  addGroup: "/v1/private/user/addGroup",
  removeGroup: "/v1/private/user/removeGroup",
  favoriteConversation: "/v1/private/board/favoriteConversation",
  broadcastConversation: "/v1/private/board/broadcastConversation",
  singleOpenBoard: "/v1/private/board/one",
  sponsored: "/v1/private/user/sponsor-adv",
  // ADV
  advSignUp: "/v1/public/adv/register",
  advProfileUpdateURL: "/v1/private/adv/update",
  cardURL: "/v1/private/adv/card",
  campaignURL: "/v1/private/adv/campaign",
  paymentURL: "/v1/private/adv/campaign/payment",
  payAllPaymentURL: "/v1/private/adv/campaign/pay-all",
  fileUpload: "/v1/private/user/uploadFile",
  advClick: "/v1/private/adv/campaign/click",
  invoiceURL: "/v1/private/adv/campaign/invoice",
  mediaURL: Config.mediaURL,

  //  SUBSCRIPTION WALL API
  getSubscriptionWall: "/v1/public/auth/subscription-wall",
  getNewsPublicList: "/v1/public/user/news",
  getTrendingPublic: "/v1/public/user/widget",
  sponsoredPublic: "/v1/public/user/sponsor-adv",
  quickLinkPublic: "/v1/public/user/myLink",
  trendingContributorsPublic: "/v1/public/user/trending",
  getOneTopicListPublic: "/v1/public/topic",
  boardDetailsPublic: "/v1/public/board/list",
  getTopicMediaPublic: "/v1/public/topic/media",
  getOnePollListPublic: "/v1/public/poll",
  userByNamePublic: "/v1/public/user/u",
  getMediaPublic: "/v1/public/user/media",
  suggestedPublic: "/v1/public/user/suggested",
  favoriteConversationPublic: "/v1/public/board/favoriteConversation",
  broadcastConversationPublic: "/v1/public/board/broadcastConversation",
  userListenPublic: "/v1/public/user/listen",
  interestsPublic: "/v1/public/user/interest",
  myTopicsPublic: "/v1/public/topic/mytopics",
  getPollListPublic: "/v1/public/poll/mypolls",
  searchListPublic: "/v1/public/user/search",
};
