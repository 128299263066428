import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IntlContext } from "src/App";

const GettingStarted = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head mb-17">
              {localesData?.info_content?.help_center?.user_type?.title1_1}
            </Typography>

            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.registration,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.desc1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.desc2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.desc3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.desc4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.registration
                        ?.desc5,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.logging_in,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin?.desc3,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin?.desc4,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel3a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.stayloggedin?.head,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.stayloggedin?.desc1,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.stayloggedin?.desc2,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.stayloggedin?.desc3,
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                className="accordion_text"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel4a-header"
              >
                <Typography
                  variant="h5"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.resep_pass?.head,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.resep_pass?.desc4,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.resep_pass?.desc5,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.resep_pass?.desc6,
                  }}
                />
                <Typography
                  variant="h5"
                  className="mb-17"
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.info_content?.help_center?.loggedin
                        ?.resep_pass?.desc7,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GettingStarted;
