import {
  SELECT_INFO_MENU,
  TRENDING_LIST,
  QUICK_LINK_LIST,
  VIEW_TOPIC_POLL,
  INTEREST_LIST,
} from "../constants";

export const selectedInfoLeftMenu = (data) => {
  return {
    type: SELECT_INFO_MENU,
    data: data,
  };
};

export const trendingList = (data) => {
  return {
    type: TRENDING_LIST,
    data: data,
  };
};

export const quicklinkList = (data) => {
  return {
    type: QUICK_LINK_LIST,
    data: data,
  };
};

export const viewTopicPoll = (data) => {
  return {
    type: VIEW_TOPIC_POLL,
    data: data,
  };
};

export const interestData = (data) => {
  return {
    type: INTEREST_LIST,
    data: data,
  };
};
