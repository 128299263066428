import socketio from 'socket.io-client';
import React from 'react';
import { Config } from 'src/helpers/context/config';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

let socketInitialize = null;
const userDetail = fetchFromStorage(identifiers.userData);

socketInitialize = socketio(Config.socketUrl, {
  jsonp: false,
  path: Config.socketPath,
  upgrade: false,
  reconnection: true,
});

export const socket = socketInitialize.connect();

export const socketJoin =
  userDetail !== null &&
  socket.emit('JoinSocket', {
    userId: userDetail?._id,
  });

export const SocketContext = React.createContext();
