import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { IntlContext } from "src/App";
import "../../info.scss";

const Site = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className="main-content-block">
      <Grid container className="main-grid-container main-section-block">
        <Grid item xs={12} sm={12} md={12} lg={12} className="">
          <Box className="plateform_top">
            <Typography variant="h3" className="info_head">
              {localesData?.info_content?.site?.title}
            </Typography>
            <Typography variant="h5" className="color-medium-gray mb-17">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.site?.head1,
                }}
              />
            </Typography>
            <Typography variant="h5" className="color-medium-gray mb-17">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.site?.head1_1,
                }}
              />
            </Typography>
            <Typography
              variant="h5"
              className="plateform_top_inner ml-18 mb-17"
            >
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line1_1,
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line1_2,
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line1_3,
                    }}
                  />
                </li>
                {/* <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line1_4,
                    }}
                  />
                </li> */}
              </ul>
            </Typography>
            <Typography variant="h5">
              <span
                dangerouslySetInnerHTML={{
                  __html: localesData?.info_content?.site?.head2,
                }}
              />
            </Typography>
            <Typography variant="h5" className="plateform_top_inner ml-18">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line2_1,
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line2_2,
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line2_3,
                    }}
                  />
                </li>
                {/* <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line2_4,
                    }}
                  />
                </li>
                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: localesData?.info_content?.site?.line2_5,
                    }}
                  />
                </li> */}
              </ul>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Site;
