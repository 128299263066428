import React from 'react';
import { MenuItem, Box, Typography, MenuList, Menu } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';

import { identifiers } from 'src/helpers/constants/identifier';

import {
  Config,
  fetchFromStorage,
  removeFromStorage
} from 'src/helpers/context';

import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';

import '../layout.scss';

const AccountDetails = ({ localesData, handleCloseMenu, anchorElMenu }) => {
  const userRoute = Config?.userRoutePath;
  const navigate = useNavigate();
  const deviceID = fetchFromStorage(identifiers.deviceid);
  const loginUser = fetchFromStorage(identifiers.userData);

  const information_menu = [
    {
      href: `${userRoute}info/platform`,
      title: localesData?.register?.about_sezz_you
    },
    { href: `${userRoute}help`, title: localesData?.register?.help_centre },
    // { href: "/app", title: localesData?.register?.sezz_you_app },
    {
      href: `${userRoute}announcements`,
      title: localesData?.info?.announcements
    }
  ];
  const policy_Menu = [
    {
      href: `${userRoute}agreement`,
      title: localesData?.register?.user_agreement
    },
    {
      href: `${userRoute}privacy-policy`,
      title: localesData?.register?.privacy_policy
    }
  ];

  const logout = async () => {
    if (deviceID) {
      const requestData = {
        deviceId: deviceID
      };
      await axiosInstance.post(URLS.logout, requestData);
    }
    window.location = userRoute;
    removeFromStorage(identifiers.authData);
    removeFromStorage(identifiers.userData);
    removeFromStorage(identifiers.acceptlanguage);
    removeFromStorage(identifiers.expiredTime);
    removeFromStorage(identifiers.subNewList);
    removeFromStorage(identifiers.subNewArray);
  };

  return (
    <Menu
      anchorEl={anchorElMenu}
      open={Boolean(anchorElMenu)}
      onClose={handleCloseMenu}
      onClick={handleCloseMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      className="menu-dropdown-list"
      id="account-menu-list"
    >
      {/* <MenuItem> */}
      <Box className="topbar-dropdown-list">
        <Box marginTop="5px">
          <Typography variant="h5" className="topbar-menu-title">
            {localesData.info.account}
          </Typography>
          <MenuList className="topbar-menu-list">
            <MenuItem
              className="topbar-menu-item"
              onClick={() => {
                loginUser?.subclass_id === 3
                  ? navigate(`${userRoute}adv/profile`)
                  : navigate(`${userRoute}user-profile`);
              }}
            >
              {localesData.user_profile.my_account}
            </MenuItem>
            {loginUser?.subclass_id !== 3 && (
              <MenuItem
                className="topbar-menu-item"
                onClick={() => navigate(`${userRoute}adv/campaign`)}
              >
                {localesData.adv.my_adv}
              </MenuItem>
            )}
            {/* <Box className="home-topbar-divider" /> */}
            <MenuItem className="topbar-menu-item" onClick={logout}>
              {localesData.sign_out}
            </MenuItem>
          </MenuList>
        </Box>
        <Box marginTop="18px">
          <Typography variant="h5" className="topbar-menu-title">
            {localesData.info.information}
          </Typography>
          <MenuList className="topbar-menu-list">
            {information_menu?.map((item, i) => (
              <Box key={i}>
                <MenuItem
                  className="topbar-menu-item"
                  onClick={() => navigate(item?.href)}
                >
                  {item?.title}
                </MenuItem>
                {/* <Box className="home-topbar-divider"></Box> */}
              </Box>
            ))}
          </MenuList>
        </Box>
        <Box marginTop="18px">
          <Typography variant="h5" className="topbar-menu-title">
            {localesData.info.policies}
          </Typography>
          <MenuList className="topbar-menu-list">
            {policy_Menu?.map((item, i) => (
              <Box key={i}>
                <MenuItem
                  className="topbar-menu-item"
                  onClick={() => navigate(item?.href)}
                >
                  {item?.title}
                </MenuItem>
                {/* <Box className="home-topbar-divider"></Box> */}
              </Box>
            ))}
          </MenuList>
        </Box>
      </Box>
      {/* </MenuItem> */}
    </Menu>
  );
};

export default AccountDetails;
