import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Announcements from "src/views/component/application/Info/Announcement";
import { IntlContext } from "src/App";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const AnnouncementsPage = () => {
  const classes = useStyles();
  const intlContext = React.useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Page
      className={classes.root}
      title={localesData.register.about_sezz_announcements}
      description={localesData?.titles?.app?.welcome_desc}
    >
      <Announcements />
    </Page>
  );
};

export default AnnouncementsPage;
