import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import UserAggreement from "src/views/component/application/Info/UserAgreement";
import { IntlContext } from "src/App";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const UserAggreementPage = () => {
  const classes = useStyles();
  const intlContext = React.useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Page
      className={classes.root}
      title={localesData.agreement.title}
      description={localesData?.titles?.app?.welcome_desc}
    >
      <UserAggreement />
    </Page>
  );
};

export default UserAggreementPage;
