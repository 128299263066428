import banner1 from '../../assets/images/banner/banner-bg1.webp';
import banner2 from '../../assets/images/banner/banner-bg2.webp';
import banner3 from '../../assets/images/banner/banner-bg3.webp';
import banner4 from '../../assets/images/banner/banner-bg4.webp';
import banner5 from '../../assets/images/banner/banner-bg5.webp';
import banner6 from '../../assets/images/banner/banner-bg6.webp';
import banner_1 from '../../assets/images/banner/banner-1.webp';
import banner_2 from '../../assets/images/banner/banner-2.webp';
import banner_3 from '../../assets/images/banner/banner-3.webp';
import banner_4 from '../../assets/images/banner/banner-4.webp';
import banner_5 from '../../assets/images/banner/banner-5.webp';
import banner_6 from '../../assets/images/banner/banner-6.webp';

export const identifiers = {
  authData: 'authData',
  deviceid: 'deviceId',
  userData: 'userData',
  advUserData: 'advUserData',
  acceptlanguage: 'acceptlanguage',
  expiredTime: 'expiredTime',
  subscriptionWall: 'subscriptionWall',
  subscriptionContent: 'subscriptionContent',
  isBot: 'isBot',
  subNewList: 'subNewList',
  subNewArray: 'subNewArray',
  subContentVisibility: 'subContentVisibility',
  welcomePageSlider: [
    { id: 1, url: banner1 },
    { id: 2, url: banner2 },
    { id: 3, url: banner3 },
    { id: 4, url: banner4 },
    { id: 5, url: banner5 },
    { id: 6, url: banner6 }
  ],
  welcomePageSliderResponsive: [
    { id: 1, url: banner_1 },
    { id: 2, url: banner_2 },
    { id: 3, url: banner_3 },
    { id: 4, url: banner_4 },
    { id: 5, url: banner_5 },
    { id: 6, url: banner_6 }
  ],
  languageList: [
    { title: 'English', value: 'en' },
    { title: 'German', value: 'de' }
  ]
};
