import React, { useContext } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { IntlContext } from 'src/App';

import '../../application/Info/info.scss';
import { Config } from 'src/helpers/context';

const Terms = () => {
  const userRoute = Config?.userRoutePath;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <>
      <Box className='main-content-block'>
        <Grid container className='main-grid-container main-section-block'>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <Box>
              <Typography variant='h4' className='scope_text mb-17'>
                {localesData?.agreement?.head1}
              </Typography>
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc1,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc2,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc3,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc4,
                  }}
                />
              </Box>
              <Typography variant='h4' className='scope_text mb-17'>
                {localesData?.agreement?.head2}
              </Typography>
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc5,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc6,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc7,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc8,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head3,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc9,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc10,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc11,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head4,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc12,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head5,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc13,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc14,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc15,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head6,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc16,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc17,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc18,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc19,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head7,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc20,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc21,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc22,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head8,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc23,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc24,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc25?.replace(
                      /\${userRoute}/g,
                      userRoute
                    ),
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head9,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc26,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc27,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc28,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc29,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head10,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc30,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head11,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc31,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc32,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc33,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc34,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc35,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc36,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head12,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc37,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head13,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc38,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc39,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc40,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc41,
                  }}
                />
              </Box>
              <Typography
                variant='h4'
                className='scope_text mb-17'
                dangerouslySetInnerHTML={{
                  __html: localesData?.agreement?.head14,
                }}
              />
              <Box>
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc42,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc43,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc44,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc45,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc46,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc47,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc48,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc49,
                  }}
                />
                <Typography
                  variant='h5'
                  className='mb-17'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.agreement?.desc50,
                  }}
                />
              </Box>
            </Box>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Terms;
